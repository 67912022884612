import React from "react";
import { Grid, Box, Typography, Container, Button }  from '@mui/material';
import theme from '../../theme/theme.js';

const saly = "mui-artemis-assets/saly.png";

const Banner = (props) => {
    const styles = {
        section: {
            py: theme.spacing(2),
        },
        wrapper: {
            position: 'relative',
            p: theme.spacing(4),
            [theme.breakpoints.up('md')]: {
                py: theme.spacing(10.5),
            },
            backgroundColor: theme.palette.secondary[500],
            borderRadius: theme.spacing(0.5),
            textAlign: 'center',
            backgroundImage: `url(${saly})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            overflow: 'hidden'
        },
        title: {
            position: 'relative',
            zIndex: 1,
            color: theme.palette.error[300],
            mb: theme.spacing(1),
            display: 'inline-block'
        },
        subtitle: {
            position: 'relative',
            zIndex: 1,
            color: theme.palette.primary[100],
            maxWidth: theme.spacing(50),
            m: '0 auto',
            mb: theme.spacing(2)
        },
        text: {
            color: theme.palette.common.white,
            mr: theme.spacing(1)
        },
        button: {
            position: 'relative',
            zIndex: 1,
        },
        buttonText: {
            ml: theme.spacing(0.8)
        },
        bg: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            opacity: 0.8,
            backgroundColor: theme.palette.secondary[500],
        }
    }

    const content = {
        'Typography1-1': 'Create',
        'Typography1-2': 'CRM Reports',
        'Typography1-3': 'Cause marketing is marketing dony by a for-profit business that seeks to increase profits to better society',
        'Image1-1': 'mui-artemis-assets/pointer-red.svg',
        'Typography1-4': 'Create First Report',
        ...props.content
    }

    return (
        <Box component="section" sx={styles.section}>
            <Container>
                <Grid container>
                    <Grid item xs>
                        <Box sx={styles.wrapper}>
                            <Typography variant="h2" sx={styles.title}>
                                <Typography variant="h2" component="span" sx={styles.text}>{content['Typography1-1']}</Typography>
                                <Typography variant="h2" component="span">{content['Typography1-2']}</Typography>
                            </Typography>
                            <Typography variant="body2" sx={styles.subtitle}>{content['Typography1-3']}</Typography> 
                            <Button variant="contained" color="error" sx={styles.button}>
                                <Box component="img" src={content['Image1-1']}></Box>
                                <Typography variant="button" sx={styles.buttonText}>{content['Typography1-4']}</Typography>
                            </Button>
                            <Box sx={styles.bg}></Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default Banner;