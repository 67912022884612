import { Grid, Box, Typography, Container, Chip, Avatar, Button, AvatarGroup } from '@mui/material';
import theme from '../../theme/theme';

const Projects = (props) => {
    const styles = {
        section: {
            py: theme.spacing(2)
        },
        card: {
            padding: theme.spacing(2),
            backgroundColor: theme.palette.common.white,
            borderRadius: theme.spacing(0.5),
            boxShadow: theme.shadows[1]
        },
        header: {
            position: 'relative'
        },
        button1: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            fontSize: theme.spacing(1.5),
            height: theme.spacing(3)
        },
        img: {
            width: '100%',
            height: theme.spacing(19.375),
            objectFit: 'cover',
            borderRadius: theme.spacing(0.5)
        },
        button2: {
            minWidth: 0,
            p: theme.spacing(1),
            backgroundColor: theme.palette.primary[50]
        },
        subtitle1: {
            color: theme.palette.grey[800]
        },
        subtitle2: {
            color: theme.palette.grey[500],
            fontWeight: theme.typography.fontWeightRegular
        },
        chipSuccess: {
            backgroundColor: theme.palette.success[50],
            color: theme.palette.success[500],
            fontSize: theme.spacing(1.5),
        },
        chipWarning: {
            backgroundColor: theme.palette.warning[50],
            color: theme.palette.danger[500],
            fontSize: theme.spacing(1.5),
        },
        chipPrimary: {
            backgroundColor: 'transparent',
            color: theme.palette.primary[500],
            fontSize: theme.spacing(1.5),
            fontWeight: theme.typography.fontWeightMedium
        },
        avatar: {
            height: theme.spacing(4),
            width: theme.spacing(4),
        },
        button3: {
            fontSize: theme.spacing(1.5),
            height: theme.spacing(4)
        },
        list: {
            borderBottom: 1,
            borderColor: theme.palette.grey[50],
            pb: theme.spacing(2.5)
        }
    }

    const content = {
        'Image1-1': 'mui-artemis-assets/projects/header1.png',
        'Button1-1': '72 Tasks',
        'Typography1-1': 'Netflix - Streaming Platform',
        'Typography1-2': 'Production company',
        'Button1-2': <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.00001 9.33332C8.73638 9.33332 9.33334 8.73637 9.33334 7.99999C9.33334 7.26361 8.73638 6.66666 8.00001 6.66666C7.26363 6.66666 6.66667 7.26361 6.66667 7.99999C6.66667 8.73637 7.26363 9.33332 8.00001 9.33332Z" fill="#382CDD"/><path d="M3.33333 9.33332C4.06971 9.33332 4.66667 8.73637 4.66667 7.99999C4.66667 7.26361 4.06971 6.66666 3.33333 6.66666C2.59695 6.66666 2 7.26361 2 7.99999C2 8.73637 2.59695 9.33332 3.33333 9.33332Z" fill="#382CDD"/><path d="M12.6667 9.33332C13.403 9.33332 14 8.73637 14 7.99999C14 7.26361 13.403 6.66666 12.6667 6.66666C11.9303 6.66666 11.3333 7.26361 11.3333 7.99999C11.3333 8.73637 11.9303 9.33332 12.6667 9.33332Z" fill="#382CDD"/></svg>,
        'Typography1-3': 'Start Date',
        'Chip1-1': '08 March 2021',
        'Typography1-4': 'Due Date',
        'Chip1-2': '01 June 2023',
        'Typography1-5': 'Last Update',
        'Chip1-3': '2 weeks ago',
        'Avatar1-1': 'mui-artemis-assets/projects/avatar1.png',
        'Avatar1-2': 'mui-artemis-assets/projects/avatar2.png',
        'Avatar1-3': 'mui-artemis-assets/projects/avatar3.png',
        'Avatar1-4': 'mui-artemis-assets/projects/avatar4.png',
        'Avatar1-5': 'mui-artemis-assets/projects/header1.png',
        'Avatar1-6': 'mui-artemis-assets/projects/header2.png',
        'Avatar1-7': 'mui-artemis-assets/projects/header3.png',
        'Button1-3': 'View Project',
        'Image2-1': 'mui-artemis-assets/projects/header2.png',
        'Button2-1': '72 Tasks',
        'Typography2-1': 'Netflix - Streaming Platform',
        'Typography2-2': 'Production company',
        'Button2-2': <svg data-config-id="Button2-2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.00001 9.33332C8.73638 9.33332 9.33334 8.73637 9.33334 7.99999C9.33334 7.26361 8.73638 6.66666 8.00001 6.66666C7.26363 6.66666 6.66667 7.26361 6.66667 7.99999C6.66667 8.73637 7.26363 9.33332 8.00001 9.33332Z" fill="#382CDD"/><path d="M3.33333 9.33332C4.06971 9.33332 4.66667 8.73637 4.66667 7.99999C4.66667 7.26361 4.06971 6.66666 3.33333 6.66666C2.59695 6.66666 2 7.26361 2 7.99999C2 8.73637 2.59695 9.33332 3.33333 9.33332Z" fill="#382CDD"/><path d="M12.6667 9.33332C13.403 9.33332 14 8.73637 14 7.99999C14 7.26361 13.403 6.66666 12.6667 6.66666C11.9303 6.66666 11.3333 7.26361 11.3333 7.99999C11.3333 8.73637 11.9303 9.33332 12.6667 9.33332Z" fill="#382CDD"/></svg>,
        'Typography2-3': 'Start Date',
        'Chip2-1': '08 March 2021',
        'Typography2-4': 'Due Date',
        'Chip2-2': '01 June 2023',
        'Typography2-5': 'Last Update',
        'Chip2-3': '2 weeks ago',
        'Avatar2-1': 'mui-artemis-assets/projects/avatar1.png',
        'Avatar2-2': 'mui-artemis-assets/projects/avatar2.png',
        'Avatar2-3': 'mui-artemis-assets/projects/avatar3.png',
        'Avatar2-4': 'mui-artemis-assets/projects/avatar4.png',
        'Avatar2-5': 'mui-artemis-assets/projects/header1.png',
        'Avatar2-6': 'mui-artemis-assets/projects/header2.png',
        'Avatar2-7': 'mui-artemis-assets/projects/header3.png',
        'Button2-3': 'View Project',
        'Image3-1': 'mui-artemis-assets/projects/header3.png',
        'Button3-1': '72 Tasks',
        'Typography3-1': 'Netflix - Streaming Platform',
        'Typography3-2': 'Production company',
        'Button3-2': <svg data-config-id="Button3-2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.00001 9.33332C8.73638 9.33332 9.33334 8.73637 9.33334 7.99999C9.33334 7.26361 8.73638 6.66666 8.00001 6.66666C7.26363 6.66666 6.66667 7.26361 6.66667 7.99999C6.66667 8.73637 7.26363 9.33332 8.00001 9.33332Z" fill="#382CDD"/><path d="M3.33333 9.33332C4.06971 9.33332 4.66667 8.73637 4.66667 7.99999C4.66667 7.26361 4.06971 6.66666 3.33333 6.66666C2.59695 6.66666 2 7.26361 2 7.99999C2 8.73637 2.59695 9.33332 3.33333 9.33332Z" fill="#382CDD"/><path d="M12.6667 9.33332C13.403 9.33332 14 8.73637 14 7.99999C14 7.26361 13.403 6.66666 12.6667 6.66666C11.9303 6.66666 11.3333 7.26361 11.3333 7.99999C11.3333 8.73637 11.9303 9.33332 12.6667 9.33332Z" fill="#382CDD"/></svg>,
        'Typography3-3': 'Start Date',
        'Chip3-1': '08 March 2021',
        'Typography3-4': 'Due Date',
        'Chip3-2': '01 June 2023',
        'Typography3-5': 'Last Update',
        'Chip3-3': '2 weeks ago',
        'Avatar3-1': 'mui-artemis-assets/projects/avatar1.png',
        'Avatar3-2': 'mui-artemis-assets/projects/avatar2.png',
        'Avatar3-3': 'mui-artemis-assets/projects/avatar3.png',
        'Avatar3-4': 'mui-artemis-assets/projects/avatar4.png',
        'Avatar3-5': 'mui-artemis-assets/projects/header1.png',
        'Avatar3-6': 'mui-artemis-assets/projects/header2.png',
        'Avatar3-7': 'mui-artemis-assets/projects/header3.png',
        'Button3-3': 'View Project',
        "AvatarCount1-1": "+3",
        "AvatarCount1-2": "+3",
        "AvatarCount1-3": "+3",
        ...props.content
    }

    return (
        <Box component="section" sx={styles.section}>
            <Container>
                <Grid container spacing={3.75}>
                    <Grid item xs={12} md={4}>
                        <Box sx={styles.card}>
                            <Box mb={2} sx={styles.header}>
                                <Box component="img" src={content['Image1-1']} sx={styles.img} alt="" />
                                <Button size="small" variant="contained" sx={styles.button1}>{content['Button1-1']}</Button>
                            </Box>
                            <Grid container mb={2.5} justifyContent="space-between" alignItems="center" spacing={1}>
                                <Grid item>
                                    <Typography variant="subtitle1" sx={styles.subtitle1}>{content['Typography1-1']}</Typography>
                                    <Typography variant="subtitle2" sx={styles.subtitle2}>{content['Typography1-2']}</Typography>
                                </Grid>
                                <Grid item>
                                    <Button color="primary" variant="text" sx={styles.button2}>
                                        {content['Button1-2']}
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid container rowSpacing={0.75} sx={styles.list}>
                                <Grid item xs={12}>
                                    <Grid container justifyContent="space-between" alignItems="center" spacing={1}>
                                        <Grid item>
                                            <Typography variant="subtitle2" sx={styles.subtitle1}>{content['Typography1-3']}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Chip size="small" sx={styles.chipSuccess} label={content['Chip1-1']}/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container justifyContent="space-between" alignItems="center" spacing={1}>
                                        <Grid item>
                                            <Typography variant="subtitle2" sx={styles.subtitle1}>{content['Typography1-4']}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Chip size="small" sx={styles.chipWarning} label={content['Chip1-2']}/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container justifyContent="space-between" alignItems="center" spacing={1}>
                                        <Grid item>
                                            <Typography variant="subtitle2" sx={styles.subtitle1}>{content['Typography1-5']}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Chip size="small" sx={styles.chipPrimary} label={content['Chip1-3']}/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container pt={2} justifyContent="space-between" spacing={1}>
                                <Grid item>
                                    <AvatarGroup max={5}>
                                        <Avatar alt="" src={content['Avatar1-1']} sx={styles.avatar}/>
                                        <Avatar alt="" src={content['Avatar1-2']} sx={styles.avatar}/>
                                        <Avatar alt="" src={content['Avatar1-3']} sx={styles.avatar}/>
                                        <Avatar alt="" src={content['Avatar1-4']} sx={styles.avatar}/>
                                        <Avatar alt="" src={content['Avatar1-5']} sx={styles.avatar}/>
                                        <Avatar alt="" src={content['Avatar1-6']} sx={styles.avatar}/>
                                        <Avatar alt="" src={content['Avatar1-7']} sx={styles.avatar}/>
                                    </AvatarGroup>
                                </Grid>
                                <Grid item>
                                    <Button size="small" variant="contained" sx={styles.button3}>{content['Button1-3']}</Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box sx={styles.card}>
                            <Box mb={2} sx={styles.header}>
                                <Box component="img" src={content['Image2-1']} sx={styles.img} alt="" />
                                <Button size="small" variant="contained" sx={styles.button1}>{content['Button2-1']}</Button>
                            </Box>
                            <Grid container mb={2.5} justifyContent="space-between" alignItems="center" spacing={1}>
                                <Grid item>
                                    <Typography variant="subtitle1" sx={styles.subtitle1}>{content['Typography2-1']}</Typography>
                                    <Typography variant="subtitle2" sx={styles.subtitle2}>{content['Typography2-2']}</Typography>
                                </Grid>
                                <Grid item>
                                    <Button color="primary" variant="text" sx={styles.button2}>
                                        {content['Button2-2']}
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid container rowSpacing={0.75} sx={styles.list}>
                                <Grid item xs={12}>
                                    <Grid container justifyContent="space-between" alignItems="center" spacing={1}>
                                        <Grid item>
                                            <Typography variant="subtitle2" sx={styles.subtitle1}>{content['Typography2-3']}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Chip size="small" sx={styles.chipSuccess} label={content['Chip2-1']}/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container justifyContent="space-between" alignItems="center" spacing={1}>
                                        <Grid item>
                                            <Typography variant="subtitle2" sx={styles.subtitle1}>{content['Typography2-4']}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Chip size="small" sx={styles.chipWarning} label={content['Chip2-2']}/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container justifyContent="space-between" alignItems="center" spacing={1}>
                                        <Grid item>
                                            <Typography variant="subtitle2" sx={styles.subtitle1}>{content['Typography2-5']}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Chip size="small" sx={styles.chipPrimary} label={content['Chip2-3']}/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container pt={2} justifyContent="space-between" spacing={1}>
                                <Grid item>
                                    <AvatarGroup max={5} sx={styles.avatarGroup}>
                                        <Avatar alt="" src={content['Avatar2-1']} sx={styles.avatar}/>
                                        <Avatar alt="" src={content['Avatar2-2']} sx={styles.avatar}/>
                                        <Avatar alt="" src={content['Avatar2-3']} sx={styles.avatar}/>
                                        <Avatar alt="" src={content['Avatar2-4']} sx={styles.avatar}/>
                                        <Avatar alt="" src={content['Avatar2-5']} sx={styles.avatar}/>
                                        <Avatar alt="" src={content['Avatar2-6']} sx={styles.avatar}/>
                                        <Avatar alt="" src={content['Avatar2-7']} sx={styles.avatar}/>
                                    </AvatarGroup>
                                </Grid>
                                <Grid item>
                                    <Button size="small" variant="contained" sx={styles.button3}>{content['Button2-3']}</Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box sx={styles.card}>
                            <Box mb={2} sx={styles.header}>
                                <Box component="img" src={content['Image3-1']} sx={styles.img} alt="" />
                                <Button size="small" variant="contained" sx={styles.button1}>{content['Button3-1']}</Button>
                            </Box>
                            <Grid container mb={2.5} justifyContent="space-between" alignItems="center" spacing={1}>
                                <Grid item>
                                    <Typography variant="subtitle1" sx={styles.subtitle1}>{content['Typography3-1']}</Typography>
                                    <Typography variant="subtitle2" sx={styles.subtitle2}>{content['Typography3-2']}</Typography>
                                </Grid>
                                <Grid item>
                                    <Button color="primary" variant="text" sx={styles.button2}>
                                        {content['Button3-2']}
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid container rowSpacing={0.75} sx={styles.list}>
                                <Grid item xs={12}>
                                    <Grid container justifyContent="space-between" alignItems="center" spacing={1}>
                                        <Grid item>
                                            <Typography variant="subtitle2" sx={styles.subtitle1}>{content['Typography3-3']}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Chip size="small" sx={styles.chipSuccess} label={content['Chip3-1']}/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container justifyContent="space-between" alignItems="center" spacing={1}>
                                        <Grid item>
                                            <Typography variant="subtitle2" sx={styles.subtitle1}>{content['Typography3-4']}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Chip size="small" sx={styles.chipWarning} label={content['Chip3-2']}/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container justifyContent="space-between" alignItems="center" spacing={1}>
                                        <Grid item>
                                            <Typography variant="subtitle2" sx={styles.subtitle1}>{content['Typography3-5']}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Chip size="small" sx={styles.chipPrimary} label={content['Chip3-3']}/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container pt={2} justifyContent="space-between" spacing={1}>
                                <Grid item>
                                    <AvatarGroup max={5} sx={styles.avatarGroup}>
                                        <Avatar alt="" src={content['Avatar3-1']} sx={styles.avatar}/>
                                        <Avatar alt="" src={content['Avatar3-2']} sx={styles.avatar}/>
                                        <Avatar alt="" src={content['Avatar3-3']} sx={styles.avatar}/>
                                        <Avatar alt="" src={content['Avatar3-4']} sx={styles.avatar}/>
                                        <Avatar alt="" src={content['Avatar3-5']} sx={styles.avatar}/>
                                        <Avatar alt="" src={content['Avatar3-6']} sx={styles.avatar}/>
                                        <Avatar alt="" src={content['Avatar3-7']} sx={styles.avatar}/>
                                    </AvatarGroup>
                                </Grid>
                                <Grid item>
                                    <Button size="small" variant="contained" sx={styles.button3}>{content['Button3-3']}</Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default Projects;