import React from 'react';
import Menu8 from '../components/menus/Menu8';
import StatTiles1 from '../components/stat-tiles/StatTiles1';
import Projects1 from '../components/projects/Projects1';

export default function Hackathons() {
  return (
    <React.Fragment>

      <Menu8
        content={{
          'Image1-1':
            '/images/Screenshot-2024-04-19-at-12-22-30-PM-png-1713509736753.webp',
          'Link1-1': 'Home',
          'Link2-1': 'Hackathons',
          'Link5-1': 'Rank',
          'Link6-1': 'Blog',
        }}
      />



      <StatTiles1
        content={{
          'Typography1-1': 'Some Stats',
          'Typography2-1': 'Some Stats',
          'Typography3-1': 'Some Stats',
          'Typography4-2': '3600',
        }}
      />

      <Projects1 content={null} />

      <Projects1 content={null} />
    </React.Fragment>
  );
}

