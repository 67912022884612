import React from 'react';

import Menu8 from '../components/menus/Menu8';
import Content2 from '../components/content/Content2';
import KanbanBoard2 from '../components/kanban-board/KanbanBoard2';
import Projects2 from '../components/projects/Projects2';
import Banner2 from '../components/banners/Banner2';

export default function Index() {
  return (
    <React.Fragment>
      <Menu8
        content={{
          'Image1-1':
            '/images/Screenshot-2024-04-19-at-12-22-30-PM-png-1713509736753.webp',
          'Link1-1': 'Home',
          'Link2-1': 'Hackathons',
          'Link5-1': 'Rank',
        }}
      />

      <Content2
        content={{
          'Typography1-2': 'TCS Hiring\u00a0',
          'Typography1-3':
            'Register for the TCS Hiring drive, Register for the Global hackathon today and get notified with the updates',
          'Chip1-1': '01 May 2024',
          'Typography2-2': 'Invite your friends to OdinRank',
        }}
      />

      <KanbanBoard2
        content={{
          'Typography1-1': 'On going Hackathons',
          'Chip1-1': '6',
        }}
      />

      <Projects2
        content={{
          'Button1-1': '25 Positions',
          'Typography1-1': 'Google Summer Internship',
          'Typography1-2': 'Product company',
          'Chip1-1': '01 March 2024',
          'Chip1-2': '12 May 2024',
          'Chip1-3': '1 week ago',
          'Button2-1': '40 Positions',
          'Typography2-1': 'Delloit Fullstack',
          'Typography2-2': 'Product company',
          'Chip2-1': '15 April 2024',
          'Chip2-2': '15 July 2024',
          'Chip2-3': '1 day ago',
          'Button3-1': '10 Positions',
          'Typography3-1': 'Infosys Backed Developers',
          'Typography3-2': 'Product company',
          'Chip3-1': '01 April 2024',
          'Chip3-2': '07 Aug 2024',
          'Chip3-3': '2 days ago',
        }}
      />

      <Projects2
        content={{
          'Button1-1': '10 Positions',
          'Typography1-1': 'Google Summer Internship',
          'Typography1-2': 'Product company',
          'Chip1-1': '01 March 2024',
          'Chip1-2': '12 May 2024',
          'Button2-1': '35 Positions',
          'Typography2-1': 'Delloit Fullstack',
          'Typography2-2': 'Product company',
          'Chip2-1': '15 April 2024',
          'Chip2-2': '15 July 2024',
          'Chip2-3': '1 day ago',
          'Button3-1': '100+ Positions',
          'Typography3-1': 'Infosys Backed Developers',
          'Typography3-2': 'Product company',
          'Chip3-1': '01 April 2024',
          'Chip3-2': '07 Aug 2024',
          'Chip3-3': '3 days ago',
        }}
      />

      <Banner2
        content={{
          'Typography1-3': 'Join OdinRank. Access to 5+ Hackathons\u00a0',
        }}
      />
    </React.Fragment>
  );
}

