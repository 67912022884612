import React from 'react';
import Menu8 from '../components/menus/Menu8';
import Users2 from '../components/users/Users2';
import StatTables2 from '../components/stat-tables/StatTables2';

export default function Profile() {
  return (
    <React.Fragment>

      <Menu8
        content={{
          'Image1-1':
            '/images/Screenshot-2024-04-19-at-12-22-30-PM-png-1713509736753.webp',
          'Link1-1': 'Home',
          'Link2-1': 'Hackathons',
          'Link5-1': 'Rank',
          'Link6-1': 'Blog',
        }}
      />


      <Users2
        content={{
          'Typography1-8': 'Skills',
          'Typography1-9': 'SQL, MongoDB, Java, React',
        }}
      />

      <StatTables2
        content={{
          'Typography28-1': 'My Skills',
          'Typography29-1': 'MySQL',
          'Typography30-1': 'Java',
          'Typography31-1': 'React',
          'Typography32-1': 'MongoDB',
          'Typography33-1': 'C++',
          'Typography34-1': 'System Design',
          'Typography35-1': 'DS &amp; Algo',
          'Typography1-1': 'Challenges Attempted',
          'Tab1-1': 'Global\u00a0',
          'Tab1-2': 'Hackathons',
          'Tab1-3': 'Others',
          'Typography4-2': '85%',
        }}
      />
    </React.Fragment>
  );
}

