import { useState } from 'react';
import { Grid, Box, Typography, Container, Chip, Button, Link, Checkbox, FormControl, Select, MenuItem, Pagination, Stack }  from '@mui/material';
import theme from '../../theme/theme';

const UserTable = (props) => {
    const [age, setAge] = useState('');

    const handleChange = (event) => {
      setAge(event.target.value);
    };
    const styles = {
        section: {
            py: theme.spacing(2)
        },
        scroll: {
            overflow: 'auto'
        },
        body: {
            width: theme.spacing(144),
            [theme.breakpoints.up('lg')]: {
                width: 'auto'
            }
        },
        thLeftRadius: {
            display: 'flex',
            alignItems: 'center',
            backgroundColor: theme.palette.grey[50] + '60',
            height: theme.spacing(5.875),
            pl: theme.spacing(3),
            borderRadius: `${theme.spacing(0.5)} 0 0 0`
        },
        thRightRadius: {
            display: 'flex',
            alignItems: 'center',
            backgroundColor: theme.palette.grey[50] + '60',
            height: theme.spacing(5.875),
            pl: theme.spacing(3),
            borderRadius: `0 ${theme.spacing(0.5)} 0 0`
        },
        tdBottomRadius: {
            backgroundColor: theme.palette.common.white,
            pl: theme.spacing(3),
            height: theme.spacing(8),
            display: 'flex',
            alignItems: 'center',
            borderBottom: 1,
            borderColor: theme.palette.grey[50],
            borderRadius: `0 0 ${theme.spacing(0.5)} ${theme.spacing(0.5)}`
        },
        th: {
            display: 'flex',
            alignItems: 'center',
            backgroundColor: theme.palette.grey[50] + '60',
            height: theme.spacing(5.875),
            pl: theme.spacing(3),
        },
        td: {
           backgroundColor: theme.palette.common.white,
           pl: theme.spacing(3),
           height: theme.spacing(8),
           display: 'flex',
           alignItems: 'center',
           borderBottom: 1,
           borderColor: theme.palette.grey[50]
        },
        thTitle: {
            color: theme.palette.grey[500],
        },
        title: {
            color: theme.palette.grey[800]
        },
        subtitle: {
            color: theme.palette.grey[500],
            fontWeight: theme.typography.fontWeightRegular
        },
        checkbox: {
            color: theme.palette.grey[100]
        },
        chip: {
            fontSize: theme.spacing(1.5)
        },
        button: {
            borderColor: theme.palette.grey[100],
            fontWeight: theme.typography.fontWeightRegular,
            fontSize: theme.spacing(1.5),
            minWidth:0,
        },
        buttonEllipsis: {
            minWidth: 0,
        },
        link: {
            textDecoration: 'none',
            color: theme.palette.primary[500],
            '&:hover': {
                color: theme.palette.primary[600],
            }
        },
        page: {
            color: theme.palette.grey[500]
        },
        select: {
            height: theme.spacing(4),
            width: theme.spacing(8.875),
            fontSize: theme.spacing(1.5),
            color: theme.palette.grey[500],
            backgroundColor: theme.palette.common.white
        },
        pagination: {
            "& .MuiPaginationItem-root": {
                color: theme.palette.grey[500],
                fontWeight: theme.typography.fontWeightMedium,
                fontSize: theme.spacing(1.5)
            },
            "& .css-10bvpnq-MuiButtonBase-root-MuiPaginationItem-root:not(.Mui-selected)": {
                backgroundColor: theme.palette.common.white,
                border: 1,
                borderColor: theme.palette.grey[100]
            },
            "& .Mui-selected": {
                color: theme.palette.common.white
            }
        }
    }

    const content = {
        'Typography1-1': 'Person',
        'Image1-1': 'mui-artemis-assets/user-tables/avatar.png',
        'Typography1-2': 'Bernard Ford',
        'Typography1-3': 'berford@gmail.com',
        'Image1-2': 'mui-artemis-assets/user-tables/avatar.png',
        'Typography1-4': 'Bernard Ford',
        'Typography1-5': 'berford@gmail.com',
        'Image1-3': 'mui-artemis-assets/user-tables/avatar.png',
        'Typography1-6': 'Bernard Ford',
        'Typography1-7': 'berford@gmail.com',
        'Image1-4': 'mui-artemis-assets/user-tables/avatar.png',
        'Typography1-8': 'Bernard Ford',
        'Typography1-9': 'berford@gmail.com',
        'Image1-5': 'mui-artemis-assets/user-tables/avatar.png',
        'Typography1-10': 'Bernard Ford',
        'Typography1-11': 'berford@gmail.com',
        'Image1-6': 'mui-artemis-assets/user-tables/avatar.png',
        'Typography1-12': 'Bernard Ford',
        'Typography1-13': 'berford@gmail.com',
        'Image1-7': 'mui-artemis-assets/user-tables/avatar.png',
        'Typography1-14': 'Bernard Ford',
        'Typography1-15': 'berford@gmail.com',
        'Image1-8': 'mui-artemis-assets/user-tables/avatar.png',
        'Typography1-16': 'Bernard Ford',
        'Typography1-17': 'berford@gmail.com',
        'Image1-9': 'mui-artemis-assets/user-tables/avatar.png',
        'Typography1-18': 'Bernard Ford',
        'Typography1-19': 'berford@gmail.com',
        'Image1-10': 'mui-artemis-assets/user-tables/avatar.png',
        'Typography1-20': 'Bernard Ford',
        'Typography1-21': 'berford@gmail.com',
        'Image1-11': 'mui-artemis-assets/user-tables/avatar.png',
        'Typography1-22': 'Bernard Ford',
        'Typography1-23': 'berford@gmail.com',
        'Image1-12': 'mui-artemis-assets/user-tables/avatar.png',
        'Typography1-24': 'Bernard Ford',
        'Typography1-25': 'berford@gmail.com',
        'Typography2-1': 'Company',
        'Typography2-2': 'Dunder Mifflin',
        'Typography2-3': 'Dunder Mifflin',
        'Typography2-4': 'Dunder Mifflin',
        'Typography2-5': 'Dunder Mifflin',
        'Typography2-6': 'Dunder Mifflin',
        'Typography2-7': 'Dunder Mifflin',
        'Typography2-8': 'Dunder Mifflin',
        'Typography2-9': 'Dunder Mifflin',
        'Typography2-10': 'Dunder Mifflin',
        'Typography2-11': 'Dunder Mifflin',
        'Typography2-12': 'Dunder Mifflin',
        'Typography2-13': 'Dunder Mifflin',
        'Typography3-1': 'Team',
        'Chip3-1': 'Growth',
        'Chip3-2': 'Growth',
        'Chip3-3': 'Growth',
        'Chip3-4': 'Growth',
        'Chip3-5': 'Growth',
        'Chip3-6': 'Growth',
        'Chip3-7': 'Growth',
        'Chip3-8': 'Growth',
        'Chip3-9': 'Growth',
        'Chip3-10': 'Growth',
        'Chip3-11': 'Growth',
        'Chip3-12': 'Growth',
        'Typography4-1': 'Tags',
        'Button4-1': 'Marketing',
        'Button4-2': 'Design',
        'Button4-3': 'Product Development',
        'Button4-4': '+2',
        'Button4-5': 'Marketing',
        'Button4-6': 'Design',
        'Button4-7': 'Product Development',
        'Button4-8': '+2',
        'Button4-9': 'Marketing',
        'Button4-10': 'Design',
        'Button4-11': 'Product Development',
        'Button4-12': '+2',
        'Button4-13': 'Marketing',
        'Button4-14': 'Design',
        'Button4-15': 'Product Development',
        'Button4-16': '+2',
        'Button4-17': 'Marketing',
        'Button4-18': 'Design',
        'Button4-19': 'Product Development',
        'Button4-20': '+2',
        'Button4-21': 'Marketing',
        'Button4-22': 'Design',
        'Button4-23': 'Product Development',
        'Button4-24': '+2',
        'Button4-25': 'Marketing',
        'Button4-26': 'Design',
        'Button4-27': 'Product Development',
        'Button4-28': '+2',
        'Button4-29': 'Marketing',
        'Button4-30': 'Design',
        'Button4-31': 'Product Development',
        'Button4-32': '+2',
        'Button4-33': 'Marketing',
        'Button4-34': 'Design',
        'Button4-35': 'Product Development',
        'Button4-36': '+2',
        'Button4-37': 'Marketing',
        'Button4-38': 'Design',
        'Button4-39': 'Product Development',
        'Button4-40': '+2',
        'Button4-41': 'Marketing',
        'Button4-42': 'Design',
        'Button4-43': 'Product Development',
        'Button4-44': '+2',
        'Button4-45': 'Marketing',
        'Button4-46': 'Design',
        'Button4-47': 'Product Development',
        'Button4-48': '+2',
        'Image5-1': 'mui-artemis-assets/user-tables/ellipsis.svg',
        'Image5-2': 'mui-artemis-assets/user-tables/ellipsis.svg',
        'Image5-3': 'mui-artemis-assets/user-tables/ellipsis.svg',
        'Image5-4': 'mui-artemis-assets/user-tables/ellipsis.svg',
        'Image5-5': 'mui-artemis-assets/user-tables/ellipsis.svg',
        'Image5-6': 'mui-artemis-assets/user-tables/ellipsis.svg',
        'Image5-7': 'mui-artemis-assets/user-tables/ellipsis.svg',
        'Image5-8': 'mui-artemis-assets/user-tables/ellipsis.svg',
        'Image5-9': 'mui-artemis-assets/user-tables/ellipsis.svg',
        'Image5-10': 'mui-artemis-assets/user-tables/ellipsis.svg',
        'Image5-11': 'mui-artemis-assets/user-tables/ellipsis.svg',
        'Image5-12': 'mui-artemis-assets/user-tables/ellipsis.svg',
        'Image6-1': 'mui-artemis-assets/user-tables/member.svg',
        'Link6-1': 'See all Users',
        'Typography7-1': 'Show',
        'MenuItem7-1': '15',
        'MenuItem7-2': '20',
        'MenuItem7-3': '25',
        'Typography7-2': 'of 1200',
        ...props.content
    }

    return (
        <Box component="section" sx={styles.section}>
            <Container>
                <Grid container>
                    <Grid item xs={12} sx={styles.scroll}>
                        <Box sx={styles.body}>
                            <Grid container>
                                <Grid item xs={3}>
                                    <Box sx={styles.thLeftRadius}>
                                        <Grid container alignItems="center">
                                            <Grid item>
                                                <Checkbox size="small" color="primary" sx={styles.checkbox}/>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="subtitle2" sx={styles.thTitle}>{content['Typography1-1']}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box sx={styles.td}>
                                        <Grid container alignItems="center" columnSpacing={1.5}>
                                            <Grid item>
                                                <Grid container columnSpacing={1.125}>
                                                    <Grid item>
                                                        <Checkbox size="small" sx={styles.checkbox}/>
                                                    </Grid>
                                                    <Grid item>
                                                        <Box component="img" src={content['Image1-1']} alt=""/>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="subtitle1" sx={styles.title}>{content['Typography1-2']}</Typography>
                                                <Typography variant="subtitle2" sx={styles.subtitle}>{content['Typography1-3']}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box sx={styles.td}>
                                        <Grid container alignItems="center" columnSpacing={1.5}>
                                            <Grid item>
                                                <Grid container columnSpacing={1.125}>
                                                    <Grid item>
                                                        <Checkbox size="small" sx={styles.checkbox}/>
                                                    </Grid>
                                                    <Grid item>
                                                        <Box component="img" src={content['Image1-2']} alt=""/>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="subtitle1" sx={styles.title}>{content['Typography1-4']}</Typography>
                                                <Typography variant="subtitle2" sx={styles.subtitle}>{content['Typography1-5']}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box sx={styles.td}>
                                        <Grid container alignItems="center" columnSpacing={1.5}>
                                            <Grid item>
                                                <Grid container columnSpacing={1.125}>
                                                    <Grid item>
                                                        <Checkbox size="small" sx={styles.checkbox}/>
                                                    </Grid>
                                                    <Grid item>
                                                        <Box component="img" src={content['Image1-3']} alt=""/>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="subtitle1" sx={styles.title}>{content['Typography1-6']}</Typography>
                                                <Typography variant="subtitle2" sx={styles.subtitle}>{content['Typography1-7']}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box sx={styles.td}>
                                        <Grid container alignItems="center" columnSpacing={1.5}>
                                            <Grid item>
                                                <Grid container columnSpacing={1.125}>
                                                    <Grid item>
                                                        <Checkbox size="small" sx={styles.checkbox}/>
                                                    </Grid>
                                                    <Grid item>
                                                        <Box component="img" src={content['Image1-4']} alt=""/>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="subtitle1" sx={styles.title}>{content['Typography1-8']}</Typography>
                                                <Typography variant="subtitle2" sx={styles.subtitle}>{content['Typography1-9']}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box sx={styles.td}>
                                        <Grid container alignItems="center" columnSpacing={1.5}>
                                            <Grid item>
                                                <Grid container columnSpacing={1.125}>
                                                    <Grid item>
                                                        <Checkbox size="small" sx={styles.checkbox}/>
                                                    </Grid>
                                                    <Grid item>
                                                        <Box component="img" src={content['Image1-5']} alt=""/>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="subtitle1" sx={styles.title}>{content['Typography1-10']}</Typography>
                                                <Typography variant="subtitle2" sx={styles.subtitle}>{content['Typography1-11']}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box sx={styles.td}>
                                        <Grid container alignItems="center" columnSpacing={1.5}>
                                            <Grid item>
                                                <Grid container columnSpacing={1.125}>
                                                    <Grid item>
                                                        <Checkbox size="small" sx={styles.checkbox}/>
                                                    </Grid>
                                                    <Grid item>
                                                        <Box component="img" src={content['Image1-6']} alt=""/>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="subtitle1" sx={styles.title}>{content['Typography1-12']}</Typography>
                                                <Typography variant="subtitle2" sx={styles.subtitle}>{content['Typography1-13']}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box sx={styles.td}>
                                        <Grid container alignItems="center" columnSpacing={1.5}>
                                            <Grid item>
                                                <Grid container columnSpacing={1.125}>
                                                    <Grid item>
                                                        <Checkbox size="small" sx={styles.checkbox}/>
                                                    </Grid>
                                                    <Grid item>
                                                        <Box component="img" src={content['Image1-7']} alt=""/>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="subtitle1" sx={styles.title}>{content['Typography1-14']}</Typography>
                                                <Typography variant="subtitle2" sx={styles.subtitle}>{content['Typography1-15']}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box sx={styles.td}>
                                        <Grid container alignItems="center" columnSpacing={1.5}>
                                            <Grid item>
                                                <Grid container columnSpacing={1.125}>
                                                    <Grid item>
                                                        <Checkbox size="small" sx={styles.checkbox}/>
                                                    </Grid>
                                                    <Grid item>
                                                        <Box component="img" src={content['Image1-8']} alt=""/>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="subtitle1" sx={styles.title}>{content['Typography1-16']}</Typography>
                                                <Typography variant="subtitle2" sx={styles.subtitle}>{content['Typography1-17']}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box sx={styles.td}>
                                        <Grid container alignItems="center" columnSpacing={1.5}>
                                            <Grid item>
                                                <Grid container columnSpacing={1.125}>
                                                    <Grid item>
                                                        <Checkbox size="small" sx={styles.checkbox}/>
                                                    </Grid>
                                                    <Grid item>
                                                        <Box component="img" src={content['Image1-9']} alt=""/>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="subtitle1" sx={styles.title}>{content['Typography1-18']}</Typography>
                                                <Typography variant="subtitle2" sx={styles.subtitle}>{content['Typography1-19']}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box sx={styles.td}>
                                        <Grid container alignItems="center" columnSpacing={1.5}>
                                            <Grid item>
                                                <Grid container columnSpacing={1.125}>
                                                    <Grid item>
                                                        <Checkbox size="small" sx={styles.checkbox}/>
                                                    </Grid>
                                                    <Grid item>
                                                        <Box component="img" src={content['Image1-10']} alt=""/>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="subtitle1" sx={styles.title}>{content['Typography1-20']}</Typography>
                                                <Typography variant="subtitle2" sx={styles.subtitle}>{content['Typography1-21']}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box sx={styles.td}>
                                        <Grid container alignItems="center" columnSpacing={1.5}>
                                            <Grid item>
                                                <Grid container columnSpacing={1.125}>
                                                    <Grid item>
                                                        <Checkbox size="small" sx={styles.checkbox}/>
                                                    </Grid>
                                                    <Grid item>
                                                        <Box component="img" src={content['Image1-11']} alt=""/>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="subtitle1" sx={styles.title}>{content['Typography1-22']}</Typography>
                                                <Typography variant="subtitle2" sx={styles.subtitle}>{content['Typography1-23']}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box sx={styles.td}>
                                        <Grid container alignItems="center" columnSpacing={1.5}>
                                            <Grid item>
                                                <Grid container columnSpacing={1.125}>
                                                    <Grid item>
                                                        <Checkbox size="small" sx={styles.checkbox}/>
                                                    </Grid>
                                                    <Grid item>
                                                        <Box component="img" src={content['Image1-12']} alt=""/>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="subtitle1" sx={styles.title}>{content['Typography1-24']}</Typography>
                                                <Typography variant="subtitle2" sx={styles.subtitle}>{content['Typography1-25']}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                                <Grid item xs={2}>
                                    <Box sx={styles.th}>
                                        <Grid container alignItems="center">
                                            <Grid item>
                                                <Typography variant="subtitle2" sx={styles.thTitle}>{content['Typography2-1']}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box sx={styles.td}>
                                        <Grid container alignItems="center" columnSpacing={1.5}>
                                            <Grid item>
                                                <Typography variant="subtitle1" sx={styles.title}>{content['Typography2-2']}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box sx={styles.td}>
                                        <Grid container alignItems="center" columnSpacing={1.5}>
                                            <Grid item>
                                                <Typography variant="subtitle1" sx={styles.title}>{content['Typography2-3']}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box sx={styles.td}>
                                        <Grid container alignItems="center" columnSpacing={1.5}>
                                            <Grid item>
                                                <Typography variant="subtitle1" sx={styles.title}>{content['Typography2-4']}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box sx={styles.td}>
                                        <Grid container alignItems="center" columnSpacing={1.5}>
                                            <Grid item>
                                                <Typography variant="subtitle1" sx={styles.title}>{content['Typography2-5']}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box sx={styles.td}>
                                        <Grid container alignItems="center" columnSpacing={1.5}>
                                            <Grid item>
                                                <Typography variant="subtitle1" sx={styles.title}>{content['Typography2-6']}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box sx={styles.td}>
                                        <Grid container alignItems="center" columnSpacing={1.5}>
                                            <Grid item>
                                                <Typography variant="subtitle1" sx={styles.title}>{content['Typography2-7']}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box sx={styles.td}>
                                        <Grid container alignItems="center" columnSpacing={1.5}>
                                            <Grid item>
                                                <Typography variant="subtitle1" sx={styles.title}>{content['Typography2-8']}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box sx={styles.td}>
                                        <Grid container alignItems="center" columnSpacing={1.5}>
                                            <Grid item>
                                                <Typography variant="subtitle1" sx={styles.title}>{content['Typography2-9']}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box sx={styles.td}>
                                        <Grid container alignItems="center" columnSpacing={1.5}>
                                            <Grid item>
                                                <Typography variant="subtitle1" sx={styles.title}>{content['Typography2-10']}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box sx={styles.td}>
                                        <Grid container alignItems="center" columnSpacing={1.5}>
                                            <Grid item>
                                                <Typography variant="subtitle1" sx={styles.title}>{content['Typography2-11']}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box sx={styles.td}>
                                        <Grid container alignItems="center" columnSpacing={1.5}>
                                            <Grid item>
                                                <Typography variant="subtitle1" sx={styles.title}>{content['Typography2-12']}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box sx={styles.td}>
                                        <Grid container alignItems="center" columnSpacing={1.5}>
                                            <Grid item>
                                                <Typography variant="subtitle1" sx={styles.title}>{content['Typography2-13']}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                                <Grid item xs={2}>
                                    <Box sx={styles.th}>
                                        <Grid container alignItems="center">
                                            <Grid item>
                                                <Typography variant="subtitle2" sx={styles.thTitle}>{content['Typography3-1']}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box sx={styles.td}>
                                        <Grid container alignItems="center" columnSpacing={1.5}>
                                            <Grid item>
                                                <Chip size="small" color="secondary" sx={styles.chip} label={content['Chip3-1']}/>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box sx={styles.td}>
                                        <Grid container alignItems="center" columnSpacing={1.5}>
                                            <Grid item>
                                                <Chip size="small" color="secondary" sx={styles.chip} label={content['Chip3-2']}/>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box sx={styles.td}>
                                        <Grid container alignItems="center" columnSpacing={1.5}>
                                            <Grid item>
                                                <Chip size="small" color="secondary" sx={styles.chip} label={content['Chip3-3']}/>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box sx={styles.td}>
                                        <Grid container alignItems="center" columnSpacing={1.5}>
                                            <Grid item>
                                                <Chip size="small" color="secondary" sx={styles.chip} label={content['Chip3-4']}/>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box sx={styles.td}>
                                        <Grid container alignItems="center" columnSpacing={1.5}>
                                            <Grid item>
                                                <Chip size="small" color="secondary" sx={styles.chip} label={content['Chip3-5']}/>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box sx={styles.td}>
                                        <Grid container alignItems="center" columnSpacing={1.5}>
                                            <Grid item>
                                                <Chip size="small" color="secondary" sx={styles.chip} label={content['Chip3-6']}/>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box sx={styles.td}>
                                        <Grid container alignItems="center" columnSpacing={1.5}>
                                            <Grid item>
                                                <Chip size="small" color="secondary" sx={styles.chip} label={content['Chip3-7']}/>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box sx={styles.td}>
                                        <Grid container alignItems="center" columnSpacing={1.5}>
                                            <Grid item>
                                                <Chip size="small" color="secondary" sx={styles.chip} label={content['Chip3-8']}/>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box sx={styles.td}>
                                        <Grid container alignItems="center" columnSpacing={1.5}>
                                            <Grid item>
                                                <Chip size="small" color="secondary" sx={styles.chip} label={content['Chip3-9']}/>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box sx={styles.td}>
                                        <Grid container alignItems="center" columnSpacing={1.5}>
                                            <Grid item>
                                                <Chip size="small" color="secondary" sx={styles.chip} label={content['Chip3-10']}/>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box sx={styles.td}>
                                        <Grid container alignItems="center" columnSpacing={1.5}>
                                            <Grid item>
                                                <Chip size="small" color="secondary" sx={styles.chip} label={content['Chip3-11']}/>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box sx={styles.td}>
                                        <Grid container alignItems="center" columnSpacing={1.5}>
                                            <Grid item>
                                                <Chip size="small" color="secondary" sx={styles.chip} label={content['Chip3-12']}/>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                                <Grid item xs={5}>
                                    <Box sx={styles.thRightRadius}>
                                        <Grid container alignItems="center">
                                            <Grid item>
                                                <Typography variant="subtitle2" sx={styles.thTitle}>{content['Typography4-1']}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box sx={styles.td} pr={2}>
                                        <Grid container justifyContent="space-between">
                                            <Grid item>
                                                <Grid container alignItems="center" columnSpacing={1}>
                                                    <Grid item>
                                                        <Button variant="outlined" size="small" color="grey" sx={styles.button}>{content['Button4-1']}</Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button variant="outlined" size="small" color="grey" sx={styles.button}>{content['Button4-2']}</Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button variant="outlined" size="small" color="grey" sx={styles.button}>{content['Button4-3']}</Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button variant="outlined" size="small" color="grey" sx={styles.button}>{content['Button4-4']}</Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Button variant="text" sx={styles.buttonEllipsis}>
                                                    <Box component="img" src={content['Image5-1']} sx={styles.img} alt=""/>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box sx={styles.td} pr={2}>
                                        <Grid container justifyContent="space-between">
                                            <Grid item>
                                                <Grid container alignItems="center" columnSpacing={1}>
                                                    <Grid item>
                                                        <Button variant="outlined" size="small" color="grey" sx={styles.button}>{content['Button4-5']}</Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button variant="outlined" size="small" color="grey" sx={styles.button}>{content['Button4-6']}</Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button variant="outlined" size="small" color="grey" sx={styles.button}>{content['Button4-7']}</Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button variant="outlined" size="small" color="grey" sx={styles.button}>{content['Button4-8']}</Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Button variant="text" sx={styles.buttonEllipsis}>
                                                    <Box component="img" src={content['Image5-2']} sx={styles.img} alt=""/>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box sx={styles.td} pr={2}>
                                        <Grid container justifyContent="space-between">
                                            <Grid item>
                                                <Grid container alignItems="center" columnSpacing={1}>
                                                    <Grid item>
                                                        <Button variant="outlined" size="small" color="grey" sx={styles.button}>{content['Button4-9']}</Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button variant="outlined" size="small" color="grey" sx={styles.button}>{content['Button4-10']}</Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button variant="outlined" size="small" color="grey" sx={styles.button}>{content['Button4-11']}</Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button variant="outlined" size="small" color="grey" sx={styles.button}>{content['Button4-12']}</Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Button variant="text" sx={styles.buttonEllipsis}>
                                                    <Box component="img" src={content['Image5-3']} sx={styles.img} alt=""/>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box sx={styles.td} pr={2}>
                                        <Grid container justifyContent="space-between">
                                            <Grid item>
                                                <Grid container alignItems="center" columnSpacing={1}>
                                                    <Grid item>
                                                        <Button variant="outlined" size="small" color="grey" sx={styles.button}>{content['Button4-13']}</Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button variant="outlined" size="small" color="grey" sx={styles.button}>{content['Button4-14']}</Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button variant="outlined" size="small" color="grey" sx={styles.button}>{content['Button4-15']}</Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button variant="outlined" size="small" color="grey" sx={styles.button}>{content['Button4-16']}</Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Button variant="text" sx={styles.buttonEllipsis}>
                                                    <Box component="img" src={content['Image5-4']} sx={styles.img} alt=""/>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box sx={styles.td} pr={2}>
                                        <Grid container justifyContent="space-between">
                                            <Grid item>
                                                <Grid container alignItems="center" columnSpacing={1}>
                                                    <Grid item>
                                                        <Button variant="outlined" size="small" color="grey" sx={styles.button}>{content['Button4-17']}</Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button variant="outlined" size="small" color="grey" sx={styles.button}>{content['Button4-18']}</Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button variant="outlined" size="small" color="grey" sx={styles.button}>{content['Button4-19']}</Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button variant="outlined" size="small" color="grey" sx={styles.button}>{content['Button4-20']}</Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Button variant="text" sx={styles.buttonEllipsis}>
                                                    <Box component="img" src={content['Image5-5']} sx={styles.img} alt=""/>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box sx={styles.td} pr={2}>
                                        <Grid container justifyContent="space-between">
                                            <Grid item>
                                                <Grid container alignItems="center" columnSpacing={1}>
                                                    <Grid item>
                                                        <Button variant="outlined" size="small" color="grey" sx={styles.button}>{content['Button4-21']}</Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button variant="outlined" size="small" color="grey" sx={styles.button}>{content['Button4-22']}</Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button variant="outlined" size="small" color="grey" sx={styles.button}>{content['Button4-23']}</Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button variant="outlined" size="small" color="grey" sx={styles.button}>{content['Button4-24']}</Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Button variant="text" sx={styles.buttonEllipsis}>
                                                    <Box component="img" src={content['Image5-6']} sx={styles.img} alt=""/>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box sx={styles.td} pr={2}>
                                        <Grid container justifyContent="space-between">
                                            <Grid item>
                                                <Grid container alignItems="center" columnSpacing={1}>
                                                    <Grid item>
                                                        <Button variant="outlined" size="small" color="grey" sx={styles.button}>{content['Button4-25']}</Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button variant="outlined" size="small" color="grey" sx={styles.button}>{content['Button4-26']}</Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button variant="outlined" size="small" color="grey" sx={styles.button}>{content['Button4-27']}</Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button variant="outlined" size="small" color="grey" sx={styles.button}>{content['Button4-28']}</Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Button variant="text" sx={styles.buttonEllipsis}>
                                                    <Box component="img" src={content['Image5-7']} sx={styles.img} alt=""/>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box sx={styles.td} pr={2}>
                                        <Grid container justifyContent="space-between">
                                            <Grid item>
                                                <Grid container alignItems="center" columnSpacing={1}>
                                                    <Grid item>
                                                        <Button variant="outlined" size="small" color="grey" sx={styles.button}>{content['Button4-29']}</Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button variant="outlined" size="small" color="grey" sx={styles.button}>{content['Button4-30']}</Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button variant="outlined" size="small" color="grey" sx={styles.button}>{content['Button4-31']}</Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button variant="outlined" size="small" color="grey" sx={styles.button}>{content['Button4-32']}</Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Button variant="text" sx={styles.buttonEllipsis}>
                                                    <Box component="img" src={content['Image5-8']} sx={styles.img} alt=""/>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box sx={styles.td} pr={2}>
                                        <Grid container justifyContent="space-between">
                                            <Grid item>
                                                <Grid container alignItems="center" columnSpacing={1}>
                                                    <Grid item>
                                                        <Button variant="outlined" size="small" color="grey" sx={styles.button}>{content['Button4-33']}</Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button variant="outlined" size="small" color="grey" sx={styles.button}>{content['Button4-34']}</Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button variant="outlined" size="small" color="grey" sx={styles.button}>{content['Button4-35']}</Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button variant="outlined" size="small" color="grey" sx={styles.button}>{content['Button4-36']}</Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Button variant="text" sx={styles.buttonEllipsis}>
                                                    <Box component="img" src={content['Image5-9']} sx={styles.img} alt=""/>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box sx={styles.td} pr={2}>
                                        <Grid container justifyContent="space-between">
                                            <Grid item>
                                                <Grid container alignItems="center" columnSpacing={1}>
                                                    <Grid item>
                                                        <Button variant="outlined" size="small" color="grey" sx={styles.button}>{content['Button4-37']}</Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button variant="outlined" size="small" color="grey" sx={styles.button}>{content['Button4-38']}</Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button variant="outlined" size="small" color="grey" sx={styles.button}>{content['Button4-39']}</Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button variant="outlined" size="small" color="grey" sx={styles.button}>{content['Button4-40']}</Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Button variant="text" sx={styles.buttonEllipsis}>
                                                    <Box component="img" src={content['Image5-10']} sx={styles.img} alt=""/>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box sx={styles.td} pr={2}>
                                        <Grid container justifyContent="space-between">
                                            <Grid item>
                                                <Grid container alignItems="center" columnSpacing={1}>
                                                    <Grid item>
                                                        <Button variant="outlined" size="small" color="grey" sx={styles.button}>{content['Button4-41']}</Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button variant="outlined" size="small" color="grey" sx={styles.button}>{content['Button4-42']}</Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button variant="outlined" size="small" color="grey" sx={styles.button}>{content['Button4-43']}</Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button variant="outlined" size="small" color="grey" sx={styles.button}>{content['Button4-44']}</Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Button variant="text" sx={styles.buttonEllipsis}>
                                                    <Box component="img" src={content['Image5-11']} sx={styles.img} alt=""/>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box sx={styles.td} pr={2}>
                                        <Grid container justifyContent="space-between">
                                            <Grid item>
                                                <Grid container alignItems="center" columnSpacing={1}>
                                                    <Grid item>
                                                        <Button variant="outlined" size="small" color="grey" sx={styles.button}>{content['Button4-45']}</Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button variant="outlined" size="small" color="grey" sx={styles.button}>{content['Button4-46']}</Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button variant="outlined" size="small" color="grey" sx={styles.button}>{content['Button4-47']}</Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button variant="outlined" size="small" color="grey" sx={styles.button}>{content['Button4-48']}</Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <Button variant="text" sx={styles.buttonEllipsis}>
                                                    <Box component="img" src={content['Image5-12']} sx={styles.img} alt=""/>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={12} mb={3}>
                        <Box sx={styles.tdBottomRadius}>
                            <Grid container justifyContent="center" columnSpacing={0.5}>
                                <Grid item>
                                    <Box component="img" src={content['Image6-1']} alt=""/>
                                </Grid>
                                <Grid item>
                                    <Link href="#" sx={styles.link}>
                                        <Typography variant="subtitle2">{content['Link6-1']}</Typography>
                                    </Link>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justifyContent="space-between" spacing={1}>
                            <Grid item>
                                <Grid container alignItems="center" columnSpacing={1.5}>
                                    <Grid item>
                                        <Typography variant="subtitle2" sx={styles.page}>{content['Typography7-1']}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <FormControl fullWidth>
                                            <Select
                                                value={age}
                                                onChange={handleChange}
                                                displayEmpty   
                                                sx={styles.select}                                 
                                            >
                                                <MenuItem value={""}>{content['MenuItem7-1']}</MenuItem>
                                                <MenuItem value={1}>{content['MenuItem7-2']}</MenuItem>
                                                <MenuItem value={2}>{content['MenuItem7-3']}</MenuItem>

                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="subtitle2" sx={styles.page}>{content['Typography7-2']}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Stack spacing={2}>
                                    <Pagination count={10} variant="text" color="primary" sx={styles.pagination} shape="rounded" />
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default UserTable;