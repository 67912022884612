import React from "react";
import { Grid, Box, Typography, Container }  from '@mui/material';
import theme from '../../theme/theme.js';

const Banner = (props) => {
    const styles = {
        section: {
            py: theme.spacing(2)
        },
        wrapper: {
            p: theme.spacing(4),
            [theme.breakpoints.up('md')]: {
                py: theme.spacing(7),
                px: theme.spacing(6),
            },
            position: 'relative',
            backgroundColor: theme.palette.primary[500],
            borderRadius: theme.spacing(0.5),
            overflow: 'hidden'
        },
        title: {
            position: 'relative',
            color: theme.palette.common.white,
            zIndex: 1
        },
        subtitle: {
            position: 'relative',
            color: theme.palette.primary[100],
            fontWeight: theme.typography.fontWeightMedium,
            zIndex: 1
        },
        text: {
            color: theme.palette.success[300],
            mr: theme.spacing(1)
        },
        img: {
            position: 'absolute',
            right: 0,
            top: '50%',
            transform: 'translateY(-50%)',
            maxWidth: theme.spacing(60)
        }
    }

    const content = {
        'Typography1-1': '30 Days',
        'Typography1-2': 'Free Trial',
        'Typography1-3': 'Pay 0$ for the First Month',
        'Image1-1': 'mui-artemis-assets/banner.png',
        ...props.content
    }

    return (
        <Box component="section" sx={styles.section}>
            <Container>
                <Grid container>
                    <Grid item xs>
                        <Box sx={styles.wrapper}>
                            <Typography variant="h1" sx={styles.title}>
                                <Typography variant="h1" component="span" sx={styles.text}>{content['Typography1-1']}</Typography>
                                <Typography variant="h1" component="span">{content['Typography1-2']}</Typography>
                            </Typography>
                            <Typography variant="h4" sx={styles.subtitle}>{content['Typography1-3']}</Typography> 
                            <Box component="img" sx={styles.img} src={content['Image1-1']}></Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default Banner;