import { Grid, Box, Typography, Chip, Container }  from '@mui/material';
import theme from '../../theme/theme.js';

const StatTiles = (props) => {
    const styles = {
        section: {
            py: theme.spacing(2),
        },
        box: {
            p: theme.spacing(3),
            backgroundColor: theme.palette.common.white,
            borderRadius: theme.spacing(0.5),
            boxShadow: theme.shadows[1]
        },
        subtitle: {
            color: theme.palette.grey[600],
            pl: theme.spacing(1)
        },
        unit: {
            color: theme.palette.grey[800],
            mt: theme.spacing(1),
            mb: theme.spacing(1)
        },
        chip: {
            color: theme.palette.grey[500],
            fontSize: theme.spacing(1.5),
            backgroundColor: theme.palette.grey[50]
        }
    }

    const content = {
        'Image1-1': 'mui-artemis-assets/stat-tiles/comment-message.svg',
        'Typography1-1': 'Messages',
        'Chip1-1': '30 Days',
        'Typography1-2': '1 450',
        'GridItem1-1': <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.5001 4.16667C16.3906 4.16661 16.2822 4.18812 16.1811 4.22998C16.08 4.27183 15.9881 4.33322 15.9107 4.41061C15.8333 4.488 15.7719 4.57989 15.7301 4.68103C15.6882 4.78216 15.6667 4.89055 15.6668 5V7.15495L10.4226 1.91084C10.3452 1.83344 10.2534 1.77204 10.1523 1.73015C10.0512 1.68825 9.94283 1.66669 9.83339 1.66669C9.72396 1.66669 9.6156 1.68825 9.51451 1.73015C9.41341 1.77204 9.32156 1.83344 9.2442 1.91084L6.50009 4.65495L2.08925 0.244171C1.93251 0.0902454 1.72133 0.00444584 1.50165 0.00544068C1.28197 0.00643553 1.07157 0.094144 0.916231 0.249483C0.760892 0.404822 0.673184 0.61522 0.672189 0.8349C0.671194 1.05458 0.756993 1.26576 0.910919 1.4225L5.91092 6.4225C5.98828 6.4999 6.08013 6.5613 6.18123 6.60319C6.28232 6.64508 6.39068 6.66665 6.50011 6.66665C6.60954 6.66665 6.7179 6.64508 6.819 6.60319C6.92009 6.5613 7.01194 6.4999 7.0893 6.4225L9.83342 3.67839L14.4884 8.33334H12.3334C12.1124 8.33334 11.9004 8.42113 11.7442 8.57741C11.5879 8.7337 11.5001 8.94566 11.5001 9.16667C11.5001 9.38768 11.5879 9.59965 11.7442 9.75593C11.9004 9.91221 12.1124 10 12.3334 10H16.5001C16.6095 10.0001 16.7179 9.97855 16.8191 9.9367C16.9202 9.89484 17.0121 9.83346 17.0895 9.75607C17.1669 9.67867 17.2283 9.58678 17.2701 9.48565C17.312 9.38452 17.3335 9.27612 17.3334 9.16667V5C17.3335 4.89055 17.312 4.78216 17.2701 4.68103C17.2283 4.57989 17.1669 4.488 17.0895 4.41061C17.0121 4.33322 16.9202 4.27183 16.8191 4.22998C16.7179 4.18812 16.6095 4.16661 16.5001 4.16667V4.16667Z" fill="#E85444"/></svg>,
        'Typography1-3': '25% less',
        'Image2-1': 'mui-artemis-assets/stat-tiles/briefcase-alt.svg',
        'Typography2-1': 'Tasks',
        'Chip2-1': '30 Days',
        'Typography2-2': '54',
        'GridItem2-1': <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.5001 5.83333C16.3907 5.83339 16.2823 5.81188 16.1811 5.77002C16.08 5.72817 15.9881 5.66678 15.9107 5.58939C15.8333 5.512 15.7719 5.42011 15.7301 5.31897C15.6882 5.21784 15.6667 5.10945 15.6668 5V2.84505L10.4226 8.08916C10.3453 8.16656 10.2534 8.22796 10.1523 8.26985C10.0512 8.31175 9.94286 8.33331 9.83343 8.33331C9.72399 8.33331 9.61563 8.31175 9.51454 8.26985C9.41344 8.22796 9.32159 8.16656 9.24423 8.08916L6.50012 5.34505L2.08928 9.75583C1.93254 9.90975 1.72136 9.99555 1.50168 9.99456C1.282 9.99356 1.0716 9.90586 0.916262 9.75052C0.760923 9.59518 0.673214 9.38478 0.672219 9.1651C0.671224 8.94542 0.757024 8.73424 0.910949 8.5775L5.91095 3.5775C5.98831 3.5001 6.08016 3.4387 6.18126 3.39681C6.28235 3.35492 6.39071 3.33335 6.50014 3.33335C6.60957 3.33335 6.71793 3.35492 6.81903 3.39681C6.92012 3.4387 7.01197 3.5001 7.08933 3.5775L9.83345 6.32161L14.4884 1.66666H12.3335C12.1124 1.66666 11.9005 1.57887 11.7442 1.42259C11.5879 1.2663 11.5001 1.05434 11.5001 0.833329C11.5001 0.612315 11.5879 0.400352 11.7442 0.244073C11.9005 0.0877924 12.1124 -4.76837e-06 12.3335 -4.76837e-06H16.5001C16.6096 -6.67572e-05 16.718 0.0214453 16.8191 0.063302C16.9202 0.105159 17.0121 0.166539 17.0895 0.243935C17.1669 0.321329 17.2283 0.413218 17.2701 0.514352C17.312 0.615484 17.3335 0.723876 17.3335 0.833329V5C17.3335 5.10945 17.312 5.21784 17.2701 5.31897C17.2283 5.42011 17.1669 5.512 17.0895 5.58939C17.0121 5.66678 16.9202 5.72817 16.8191 5.77002C16.718 5.81188 16.6096 5.83339 16.5001 5.83333Z" fill="#17BB84"/></svg>,
        'Typography2-3': '25% more',
        'Image3-1': 'mui-artemis-assets/stat-tiles/file-blank.svg',
        'Typography3-1': 'Files',
        'Chip3-1': '30 Days',
        'Typography3-2': '492',
        'GridItem3-1': <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.5001 5.83333C16.3907 5.83339 16.2823 5.81188 16.1811 5.77002C16.08 5.72817 15.9881 5.66678 15.9107 5.58939C15.8333 5.512 15.7719 5.42011 15.7301 5.31897C15.6882 5.21784 15.6667 5.10945 15.6668 5V2.84505L10.4226 8.08916C10.3453 8.16656 10.2534 8.22796 10.1523 8.26985C10.0512 8.31175 9.94286 8.33331 9.83343 8.33331C9.72399 8.33331 9.61563 8.31175 9.51454 8.26985C9.41344 8.22796 9.32159 8.16656 9.24423 8.08916L6.50012 5.34505L2.08928 9.75583C1.93254 9.90975 1.72136 9.99555 1.50168 9.99456C1.282 9.99356 1.0716 9.90586 0.916262 9.75052C0.760923 9.59518 0.673214 9.38478 0.672219 9.1651C0.671224 8.94542 0.757024 8.73424 0.910949 8.5775L5.91095 3.5775C5.98831 3.5001 6.08016 3.4387 6.18126 3.39681C6.28235 3.35492 6.39071 3.33335 6.50014 3.33335C6.60957 3.33335 6.71793 3.35492 6.81903 3.39681C6.92012 3.4387 7.01197 3.5001 7.08933 3.5775L9.83345 6.32161L14.4884 1.66666H12.3335C12.1124 1.66666 11.9005 1.57887 11.7442 1.42259C11.5879 1.2663 11.5001 1.05434 11.5001 0.833329C11.5001 0.612315 11.5879 0.400352 11.7442 0.244073C11.9005 0.0877924 12.1124 -4.76837e-06 12.3335 -4.76837e-06H16.5001C16.6096 -6.67572e-05 16.718 0.0214453 16.8191 0.063302C16.9202 0.105159 17.0121 0.166539 17.0895 0.243935C17.1669 0.321329 17.2283 0.413218 17.2701 0.514352C17.312 0.615484 17.3335 0.723876 17.3335 0.833329V5C17.3335 5.10945 17.312 5.21784 17.2701 5.31897C17.2283 5.42011 17.1669 5.512 17.0895 5.58939C17.0121 5.66678 16.9202 5.72817 16.8191 5.77002C16.718 5.81188 16.6096 5.83339 16.5001 5.83333Z" fill="#17BB84"/></svg>,
        'Typography3-3': '25% more',
        'Image4-1': 'mui-artemis-assets/stat-tiles/user-square.svg',
        'Typography4-1': 'Members',
        'Chip4-1': '30 Days',
        'Typography4-2': '235',
        'GridItem4-1': <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.5001 4.16667C16.3906 4.16661 16.2822 4.18812 16.1811 4.22998C16.08 4.27183 15.9881 4.33322 15.9107 4.41061C15.8333 4.488 15.7719 4.57989 15.7301 4.68103C15.6882 4.78216 15.6667 4.89055 15.6668 5V7.15495L10.4226 1.91084C10.3452 1.83344 10.2534 1.77204 10.1523 1.73015C10.0512 1.68825 9.94283 1.66669 9.83339 1.66669C9.72396 1.66669 9.6156 1.68825 9.51451 1.73015C9.41341 1.77204 9.32156 1.83344 9.2442 1.91084L6.50009 4.65495L2.08925 0.244171C1.93251 0.0902454 1.72133 0.00444584 1.50165 0.00544068C1.28197 0.00643553 1.07157 0.094144 0.916231 0.249483C0.760892 0.404822 0.673184 0.61522 0.672189 0.8349C0.671194 1.05458 0.756993 1.26576 0.910919 1.4225L5.91092 6.4225C5.98828 6.4999 6.08013 6.5613 6.18123 6.60319C6.28232 6.64508 6.39068 6.66665 6.50011 6.66665C6.60954 6.66665 6.7179 6.64508 6.819 6.60319C6.92009 6.5613 7.01194 6.4999 7.0893 6.4225L9.83342 3.67839L14.4884 8.33334H12.3334C12.1124 8.33334 11.9004 8.42113 11.7442 8.57741C11.5879 8.7337 11.5001 8.94566 11.5001 9.16667C11.5001 9.38768 11.5879 9.59965 11.7442 9.75593C11.9004 9.91221 12.1124 10 12.3334 10H16.5001C16.6095 10.0001 16.7179 9.97855 16.8191 9.9367C16.9202 9.89484 17.0121 9.83346 17.0895 9.75607C17.1669 9.67867 17.2283 9.58678 17.2701 9.48565C17.312 9.38452 17.3335 9.27612 17.3334 9.16667V5C17.3335 4.89055 17.312 4.78216 17.2701 4.68103C17.2283 4.57989 17.1669 4.488 17.0895 4.41061C17.0121 4.33322 16.9202 4.27183 16.8191 4.22998C16.7179 4.18812 16.6095 4.16661 16.5001 4.16667V4.16667Z" fill="#E85444"/></svg>,
        'Typography4-3': '25% less',
        ...props.content
    }

    return (
        <Box component="section" sx={styles.section}>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={6} md={3}>
                        <Box sx={styles.box}>
                            <Grid container justifyContent="space-between" spacing={1}>
                                <Grid item>
                                    <Grid container alignItems="center">
                                        <Grid item>
                                            <Box component="img" src={content['Image1-1']} alt=""/>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle1" sx={styles.subtitle}>{content['Typography1-1']}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs="auto">
                                    <Chip variant="filled" size="small" sx={styles.chip} label={content['Chip1-1']} />
                                </Grid>
                            </Grid>
                            <Typography variant="h3" sx={styles.unit}>{content['Typography1-2']}</Typography>
                            <Grid container alignItems="center">
                                <Grid item pr={1}>
                                    {content['GridItem1-1']}
                                </Grid>
                                <Grid item>
                                    <Typography variant="subtitle2" color="error" >{content['Typography1-3']}</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Box sx={styles.box}>
                            <Grid container justifyContent="space-between" spacing={1}>
                                <Grid item>
                                    <Grid container alignItems="center">
                                        <Grid item>
                                            <Box component="img" src={content['Image2-1']} alt=""/>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle1" sx={styles.subtitle}>{content['Typography2-1']}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs="auto">
                                    <Chip variant="filled" size="small" sx={styles.chip} label={content['Chip2-1']} />
                                </Grid>
                            </Grid>
                            <Typography variant="h3" sx={styles.unit}>{content['Typography2-2']}</Typography>
                            <Grid container alignItems="center">
                                <Grid item pr={1}>
                                    {content['GridItem2-1']}
                                </Grid>
                                <Grid item>
                                    <Typography variant="subtitle2" color="success.main" >{content['Typography2-3']}</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Box sx={styles.box}>
                            <Grid container justifyContent="space-between" spacing={1}>
                                <Grid item>
                                    <Grid container alignItems="center">
                                        <Grid item>
                                            <Box component="img" src={content['Image3-1']} alt=""/>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle1" sx={styles.subtitle}>{content['Typography3-1']}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs="auto">
                                    <Chip variant="filled" size="small" sx={styles.chip} label={content['Chip3-1']} />
                                </Grid>
                            </Grid>
                            <Typography variant="h3" sx={styles.unit}>{content['Typography3-2']}</Typography>
                            <Grid container alignItems="center">
                                <Grid item pr={1}>
                                    {content['GridItem3-1']}
                                </Grid>
                                <Grid item>
                                    <Typography variant="subtitle2" color="success.main" >{content['Typography3-3']}</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Box sx={styles.box}>
                            <Grid container justifyContent="space-between" spacing={1}>
                                <Grid item>
                                    <Grid container alignItems="center">
                                        <Grid item>
                                            <Box component="img" src={content['Image4-1']} alt=""/>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle1" sx={styles.subtitle}>{content['Typography4-1']}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs="auto">
                                    <Chip variant="filled" size="small" sx={styles.chip} label={content['Chip4-1']} />
                                </Grid>
                            </Grid>
                            <Typography variant="h3" sx={styles.unit}>{content['Typography4-2']}</Typography>
                            <Grid container alignItems="center">
                                <Grid item pr={1}>
                                    {content['GridItem4-1']}
                                </Grid>
                                <Grid item>
                                    <Typography variant="subtitle2" color="error" >{content['Typography4-3']}</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default StatTiles;