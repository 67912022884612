import { Grid, Box, Typography, Container, Chip } from '@mui/material';
import theme from '../../theme/theme';

const KanbanBoard = (props) => {
    const styles = {
        section: {
            py: theme.spacing(2)
        },
        top: {
            borderRadius: theme.spacing(0.5),
            backgroundColor: theme.palette.common.white,
            px: theme.spacing(3),
            py: theme.spacing(1.8),
            borderTop: 2,
            borderColor: theme.palette.primary[500],
            boxShadow: theme.shadows[1]
        },
        chip: {
            backgroundColor: theme.palette.primary[50],
            color: theme.palette.primary[500],
            borderRadius: theme.spacing(0.5),
            fontSize: theme.spacing(1.5),
        }
    }

    const content = {
        'Typography1-1': 'Open',
        'Chip1-1': '3',
        ...props.content
    }

    return (
        <Box component="section" sx={styles.section}>
            <Container>
                <Box sx={styles.top}>
                    <Grid container justifyContent="space-between" spacing={2}>
                        <Grid item>
                            <Typography variant="body1">{content['Typography1-1']}</Typography>
                        </Grid>
                        <Grid item>
                            <Chip size="small" sx={styles.chip} label={content['Chip1-1']}/>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Box>
    )
}

export default KanbanBoard;