import React from "react";
import {
  Routes,
  Route
} from "react-router-dom";

import { ThemeProvider } from "@mui/material/styles";
import  CssBaseline from "@mui/material/CssBaseline";
import Theme from "./theme/theme.js";

import IndexPage from "./pages/Index.js";
import RankPage from "./pages/Rank.js";
import ProfilePage from "./pages/Profile.js";
import HackathonsPage from "./pages/Hackathons.js";

function App() {
  return (
    <ThemeProvider theme={Theme}>
      <CssBaseline />
      <Routes>
        <Route path="/" element={<IndexPage />} />
         
        <Route path="/rank" element={<RankPage />} />
         
        <Route path="/profile" element={<ProfilePage />} />
         
        <Route path="/hackathons" element={<HackathonsPage />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
