import { useState } from 'react';
import { Grid, Box, Typography, Container, Tabs, Tab, Checkbox, LinearProgress, Button, Link }  from '@mui/material';
import theme from '../../theme/theme';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

const StatTables = (props) => {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const styles = {
        section: {
            py: theme.spacing(2)
        },
        box: {
            backgroundColor: theme.palette.common.white,
            borderRadius: theme.spacing(0.5),
            boxShadow: theme.shadows[1],
            width: theme.spacing(144),
            [theme.breakpoints.up('lg')]: {
                width: 'auto'
            }
        },
        box2: {
            backgroundColor: theme.palette.common.white,
            borderRadius: theme.spacing(0.5),
            boxShadow: theme.shadows[1],
            height: '100%'
        },
        scroll: {
            overflow: 'auto'
        },
        header: {
            pt: theme.spacing(2),
            px: theme.spacing(3)
        },
        title: {
            color: theme.palette.grey[800],
        },
        subtitle: {
            color: theme.palette.grey[500],
        },
        tab: {
            fontSize: theme.spacing(1.75),
            color: theme.palette.grey[300]
        },
        th: {
            backgroundColor: theme.palette.grey[50] + '60',
            height: theme.spacing(6),
            display: 'flex',
            alignItems: 'center',
            px: theme.spacing(3),
            borderBottom: 1,
            borderTop: 1,
            borderColor: theme.palette.grey[50]
        },
        td: {
            backgroundColor: theme.palette.common.white,
            height: theme.spacing(8.625),
            display: 'flex',
            alignItems: 'center',
            px: theme.spacing(3),
            borderBottom: 1,
            borderColor: theme.palette.grey[50]
        },
        checkbox: {
            padding: 0,
            color: theme.palette.grey[100],
            mb: theme.spacing(0.4),
            
        },
        subtitle2: {
            color: theme.palette.grey[500],
            fontWeight: theme.typography.fontWeightRegular
        },
        img: {
            display: 'block'
        },
        imgSocial: {
            display: 'block',
            mb: theme.spacing(1),
            [theme.breakpoints.up('md')] : {
                mb: theme.spacing(0)
            }
        },
        progress: {
            width: '100%',
            borderRadius: theme.spacing(0.25),
            backgroundColor: theme.palette.primary[50]
        },
        button: {
            minWidth: 0,
            px: theme.spacing(2)
        },
        link: {
            fontSize: theme.spacing(1.5),
            color: theme.palette.primary[500],
            '&:hover': {
                color: theme.palette.primary[600]
            }
        },
        social: {
            py: theme.spacing(3),
            borderTop: 1,
            borderColor: theme.palette.grey[50]
        }
    }

    const content = {
        'Typography1-1': 'Agents Stats dolor',
        'Typography1-2': 'Lorem ipsum dolor sit amet consectutar',
        'Tab1-1': 'Lorem Ipsum',
        'Tab1-2': 'Dolor sit',
        'Tab1-3': 'Consectutar',
        'Typography2-1': 'Authors',
        'Image2-1': 'mui-artemis-assets/stat-tables/avatar.png',
        'Typography2-2': 'John Doe',
        'Typography2-3': 'Freelancer',
        'Image2-2': 'mui-artemis-assets/stat-tables/avatar.png',
        'Typography2-4': 'John Doe',
        'Typography2-5': 'Freelancer',
        'Image2-3': 'mui-artemis-assets/stat-tables/avatar.png',
        'Typography2-6': 'John Doe',
        'Typography2-7': 'Freelancer',
        'Image2-4': 'mui-artemis-assets/stat-tables/avatar.png',
        'Typography2-8': 'John Doe',
        'Typography2-9': 'Freelancer',
        'Image2-5': 'mui-artemis-assets/stat-tables/avatar.png',
        'Typography2-10': 'John Doe',
        'Typography2-11': 'Freelancer',
        'Image2-6': 'mui-artemis-assets/stat-tables/avatar.png',
        'Typography2-12': 'John Doe',
        'Typography2-13': 'Freelancer',
        'Typography3-1': 'Company',
        'Typography3-2': 'Lorem Ipsum Company',
        'Typography3-3': 'Dolor sit Category',
        'Typography3-4': 'Lorem Ipsum Company',
        'Typography3-5': 'Dolor sit Category',
        'Typography3-6': 'Lorem Ipsum Company',
        'Typography3-7': 'Dolor sit Category',
        'Typography3-8': 'Lorem Ipsum Company',
        'Typography3-9': 'Dolor sit Category',
        'Typography3-10': 'Lorem Ipsum Company',
        'Typography3-11': 'Dolor sit Category',
        'Typography3-12': 'Lorem Ipsum Company',
        'Typography3-13': 'Dolor sit Category',
        'Typography4-1': 'Progress',
        'Typography4-2': '65%',
        'Typography4-3': '65%',
        'Typography4-4': '65%',
        'Typography4-5': '65%',
        'Typography4-6': '65%',
        'Typography4-7': '65%',
        'Image3-1': 'mui-artemis-assets/stat-tables/ellipsis-h.svg',
        'Image3-2': 'mui-artemis-assets/stat-tables/ellipsis-h.svg',
        'Image3-3': 'mui-artemis-assets/stat-tables/ellipsis-h.svg',
        'Image3-4': 'mui-artemis-assets/stat-tables/ellipsis-h.svg',
        'Image3-5': 'mui-artemis-assets/stat-tables/ellipsis-h.svg',
        'Image3-6': 'mui-artemis-assets/stat-tables/ellipsis-h.svg',
        'Image4-1': 'mui-artemis-assets/stat-tables/stats.svg',
        'Link1-1': 'See all Statistics',
        'Typography13-1': 'Authors',
        'Image12-1': 'mui-artemis-assets/stat-tables/avatar.png',
        'Typography13-2': 'John Doe',
        'Typography13-3': 'Freelancer',
        'Image12-2': 'mui-artemis-assets/stat-tables/avatar.png',
        'Typography13-4': 'John Doe',
        'Typography13-5': 'Freelancer',
        'Image12-3': 'mui-artemis-assets/stat-tables/avatar.png',
        'Typography13-6': 'John Doe',
        'Typography13-7': 'Freelancer',
        'Image12-4': 'mui-artemis-assets/stat-tables/avatar.png',
        'Typography13-8': 'John Doe',
        'Typography13-9': 'Freelancer',
        'Image12-5': 'mui-artemis-assets/stat-tables/avatar.png',
        'Typography13-10': 'John Doe',
        'Typography13-11': 'Freelancer',
        'Image12-6': 'mui-artemis-assets/stat-tables/avatar.png',
        'Typography13-12': 'John Doe',
        'Typography13-13': 'Freelancer',
        'Typography14-1': 'Company',
        'Typography14-2': 'Lorem Ipsum Company',
        'Typography14-3': 'Dolor sit Category',
        'Typography14-4': 'Lorem Ipsum Company',
        'Typography14-5': 'Dolor sit Category',
        'Typography14-6': 'Lorem Ipsum Company',
        'Typography14-7': 'Dolor sit Category',
        'Typography14-8': 'Lorem Ipsum Company',
        'Typography14-9': 'Dolor sit Category',
        'Typography14-10': 'Lorem Ipsum Company',
        'Typography14-11': 'Dolor sit Category',
        'Typography14-12': 'Lorem Ipsum Company',
        'Typography14-13': 'Dolor sit Category',
        'Typography15-1': 'Progress',
        'Typography15-2': '65%',
        'Typography15-3': '65%',
        'Typography15-4': '65%',
        'Typography15-5': '65%',
        'Typography15-6': '65%',
        'Typography15-7': '65%',
        'Image13-1': 'mui-artemis-assets/stat-tables/ellipsis-h.svg',
        'Image13-2': 'mui-artemis-assets/stat-tables/ellipsis-h.svg',
        'Image13-3': 'mui-artemis-assets/stat-tables/ellipsis-h.svg',
        'Image13-4': 'mui-artemis-assets/stat-tables/ellipsis-h.svg',
        'Image13-5': 'mui-artemis-assets/stat-tables/ellipsis-h.svg',
        'Image13-6': 'mui-artemis-assets/stat-tables/ellipsis-h.svg',
        'Image14-1': 'mui-artemis-assets/stat-tables/stats.svg',
        'Link2-1': 'See all Statistics',
        'Typography24-1': 'Authors',
        'Image22-1': 'mui-artemis-assets/stat-tables/avatar.png',
        'Typography25-2': 'John Doe',
        'Typography25-3': 'Freelancer',
        'Image22-2': 'mui-artemis-assets/stat-tables/avatar.png',
        'Typography25-4': 'John Doe',
        'Typography25-5': 'Freelancer',
        'Image22-3': 'mui-artemis-assets/stat-tables/avatar.png',
        'Typography25-6': 'John Doe',
        'Typography25-7': 'Freelancer',
        'Image22-4': 'mui-artemis-assets/stat-tables/avatar.png',
        'Typography25-8': 'John Doe',
        'Typography25-9': 'Freelancer',
        'Image22-5': 'mui-artemis-assets/stat-tables/avatar.png',
        'Typography25-10': 'John Doe',
        'Typography25-11': 'Freelancer',
        'Image22-6': 'mui-artemis-assets/stat-tables/avatar.png',
        'Typography25-12': 'John Doe',
        'Typography25-13': 'Freelancer',
        'Typography26-1': 'Company',
        'Typography26-2': 'Lorem Ipsum Company',
        'Typography26-3': 'Dolor sit Category',
        'Typography26-4': 'Lorem Ipsum Company',
        'Typography26-5': 'Dolor sit Category',
        'Typography26-6': 'Lorem Ipsum Company',
        'Typography26-7': 'Dolor sit Category',
        'Typography26-8': 'Lorem Ipsum Company',
        'Typography26-9': 'Dolor sit Category',
        'Typography26-10': 'Lorem Ipsum Company',
        'Typography26-11': 'Dolor sit Category',
        'Typography26-12': 'Lorem Ipsum Company',
        'Typography26-13': 'Dolor sit Category',
        'Typography27-1': 'Progress',
        'Typography27-2': '65%',
        'Typography27-3': '65%',
        'Typography27-4': '65%',
        'Typography27-5': '65%',
        'Typography27-6': '65%',
        'Typography27-7': '65%',
        'Image23-1': 'mui-artemis-assets/stat-tables/ellipsis-h.svg',
        'Image23-2': 'mui-artemis-assets/stat-tables/ellipsis-h.svg',
        'Image23-3': 'mui-artemis-assets/stat-tables/ellipsis-h.svg',
        'Image23-4': 'mui-artemis-assets/stat-tables/ellipsis-h.svg',
        'Image23-5': 'mui-artemis-assets/stat-tables/ellipsis-h.svg',
        'Image23-6': 'mui-artemis-assets/stat-tables/ellipsis-h.svg',
        'Image24-1': 'mui-artemis-assets/stat-tables/stats.svg',
        'Link3-1': 'See all Statistics',
        'Typography28-1': 'Social Media Traffic',
        'Typography28-2': 'Lorem ipsum dolor sit amet consectutar',
        'Image25-1': 'mui-artemis-assets/stat-tables/instagram.svg',
        'Typography29-1': 'Instagram',
        'Typography29-2': '90%',
        'Image26-1': 'mui-artemis-assets/stat-tables/twitter.svg',
        'Typography30-1': 'Twitter',
        'Typography30-2': '90%',
        'Image27-1': 'mui-artemis-assets/stat-tables/facebook.svg',
        'Typography31-1': 'Facebook',
        'Typography31-2': '90%',
        'Image28-1': 'mui-artemis-assets/stat-tables/linkedin.svg',
        'Typography32-1': 'Linkedin',
        'Typography32-2': '90%',
        'Image29-1': 'mui-artemis-assets/stat-tables/tik-tok.svg',
        'Typography33-1': 'TikTok',
        'Typography33-2': '90%',
        'Image30-1': 'mui-artemis-assets/stat-tables/reddit.svg',
        'Typography34-1': 'Reddit',
        'Typography34-2': '90%',
        'Image31-1': 'mui-artemis-assets/stat-tables/product-hunt.svg',
        'Typography35-1': 'Product hunt',
        'Typography35-2': '90%',
        ...props.content
    }

    return (
        <Box component="section" sx={styles.section}>
            <Container>
                <Grid container spacing={3.75}>
                <Grid item xs={12} lg={4}>
                        <Box sx={styles.box2}>
                            <Box sx={styles.header} pb={3}>
                                <Typography variant="h5">{content['Typography28-1']}</Typography>
                                <Typography variant="subtitle1" sx={styles.subtitle}>{content['Typography28-2']}</Typography>
                            </Box>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Grid container px={3} alignItems="center" sx={styles.social}>
                                        <Grid item xs={12} md={6}>
                                            <Grid container columnSpacing={1}>
                                                <Grid item>
                                                    <Box component="img" src={content['Image25-1']} sx={styles.imgSocial} alt=""/>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="subtitle1" sx={styles.title}>{content['Typography29-1']}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Grid container spacing={0.75}>
                                                <Grid item xs={12}>
                                                    <Typography variant="subtitle2" color="primary">{content['Typography29-2']}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <LinearProgress variant="determinate" color="primary" sx={styles.progress} value={90}/>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container px={3} alignItems="center" sx={styles.social}>
                                        <Grid item xs={12} md={6}>
                                            <Grid container columnSpacing={1}>
                                                <Grid item>
                                                    <Box component="img" src={content['Image26-1']} sx={styles.imgSocial} alt=""/>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="subtitle1" sx={styles.title}>{content['Typography30-1']}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Grid container spacing={0.75}>
                                                <Grid item xs={12}>
                                                    <Typography variant="subtitle2" color="primary">{content['Typography30-2']}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <LinearProgress variant="determinate" color="primary" sx={styles.progress} value={90}/>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container px={3} alignItems="center" sx={styles.social}>
                                        <Grid item xs={12} md={6}>
                                            <Grid container columnSpacing={1}>
                                                <Grid item>
                                                    <Box component="img" src={content['Image27-1']} sx={styles.imgSocial} alt=""/>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="subtitle1" sx={styles.title}>{content['Typography31-1']}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Grid container spacing={0.75}>
                                                <Grid item xs={12}>
                                                    <Typography variant="subtitle2" color="primary">{content['Typography31-2']}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <LinearProgress variant="determinate" color="primary" sx={styles.progress} value={90}/>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container px={3} alignItems="center" sx={styles.social}>
                                        <Grid item xs={12} md={6}>
                                            <Grid container columnSpacing={1}>
                                                <Grid item>
                                                    <Box component="img" src={content['Image28-1']} sx={styles.imgSocial} alt=""/>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="subtitle1" sx={styles.title}>{content['Typography32-1']}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Grid container spacing={0.75}>
                                                <Grid item xs={12}>
                                                    <Typography variant="subtitle2" color="primary">{content['Typography32-2']}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <LinearProgress variant="determinate" color="primary" sx={styles.progress} value={90}/>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container px={3} alignItems="center" sx={styles.social}>
                                        <Grid item xs={12} md={6}>
                                            <Grid container columnSpacing={1}>
                                                <Grid item>
                                                    <Box component="img" src={content['Image29-1']} sx={styles.imgSocial} alt=""/>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="subtitle1" sx={styles.title}>{content['Typography33-1']}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Grid container spacing={0.75}>
                                                <Grid item xs={12}>
                                                    <Typography variant="subtitle2" color="primary">{content['Typography33-2']}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <LinearProgress variant="determinate" color="primary" sx={styles.progress} value={90}/>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container px={3} alignItems="center" sx={styles.social}>
                                        <Grid item xs={12} md={6}>
                                            <Grid container columnSpacing={1}>
                                                <Grid item>
                                                    <Box component="img" src={content['Image30-1']} sx={styles.imgSocial} alt=""/>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="subtitle1" sx={styles.title}>{content['Typography34-1']}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Grid container spacing={0.75}>
                                                <Grid item xs={12}>
                                                    <Typography variant="subtitle2" color="primary">{content['Typography34-2']}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <LinearProgress variant="determinate" color="primary" sx={styles.progress} value={90}/>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container px={3} alignItems="center" sx={styles.social}>
                                        <Grid item xs={12} md={6}>
                                            <Grid container columnSpacing={1}>
                                                <Grid item>
                                                    <Box component="img" src={content['Image31-1']} sx={styles.imgSocial} alt=""/>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="subtitle1" sx={styles.title}>{content['Typography35-1']}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Grid container spacing={0.75}>
                                                <Grid item xs={12}>
                                                    <Typography variant="subtitle2" color="primary">{content['Typography35-2']}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <LinearProgress variant="determinate" color="primary" sx={styles.progress} value={90}/>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={12} lg={8} sx={styles.scroll}>
                        <Box sx={styles.box}>
                            <Box sx={styles.header}>
                                <Typography variant="h5">{content['Typography1-1']}</Typography>
                                <Typography variant="subtitle1" sx={styles.subtitle}>{content['Typography1-2']}</Typography>
                                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                    <Tab sx={styles.tab} label={content['Tab1-1']} />
                                    <Tab sx={styles.tab} label={content['Tab1-2']} />
                                    <Tab sx={styles.tab} label={content['Tab1-3']} />
                                </Tabs>
                            </Box>
                            <TabPanel value={value} index={0}>
                                <Grid container>
                                    <Grid item xs={3.66666666667}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Box sx={styles.th}>
                                                    <Grid container columnSpacing={1}>
                                                        <Grid item>
                                                            <Checkbox size="small" sx={styles.checkbox}/>
                                                        </Grid>
                                                        <Grid item pt={0.5}>
                                                            <Typography variant="subtitle2" sx={styles.subtitle}>{content['Typography2-1']}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles.td}>
                                                    <Grid container alignItems="center" columnSpacing={1.5}>
                                                        <Grid item>
                                                            <Checkbox size="small" sx={styles.checkbox}/>
                                                        </Grid>
                                                        <Grid item>
                                                            <Grid container alignItems="center" spacing={1}>
                                                                <Grid item>
                                                                    <Box component="img" src={content['Image2-1']} sx={styles.img} alt=""/>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Typography variant="subtitle1" sx={styles.title}>{content['Typography2-2']}</Typography>
                                                                    <Typography variant="subtitle2" sx={styles.subtitle2}>{content['Typography2-3']}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles.td}>
                                                    <Grid container alignItems="center" columnSpacing={1.5}>
                                                        <Grid item>
                                                            <Checkbox size="small" sx={styles.checkbox}/>
                                                        </Grid>
                                                        <Grid item>
                                                            <Grid container alignItems="center" spacing={1}>
                                                                <Grid item>
                                                                    <Box component="img" src={content['Image2-2']} sx={styles.img} alt=""/>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Typography variant="subtitle1" sx={styles.title}>{content['Typography2-4']}</Typography>
                                                                    <Typography variant="subtitle2" sx={styles.subtitle2}>{content['Typography2-5']}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles.td}>
                                                    <Grid container alignItems="center" columnSpacing={1.5}>
                                                        <Grid item>
                                                            <Checkbox size="small" sx={styles.checkbox}/>
                                                        </Grid>
                                                        <Grid item>
                                                            <Grid container alignItems="center" spacing={1}>
                                                                <Grid item>
                                                                    <Box component="img" src={content['Image2-3']} sx={styles.img} alt=""/>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Typography variant="subtitle1" sx={styles.title}>{content['Typography2-6']}</Typography>
                                                                    <Typography variant="subtitle2" sx={styles.subtitle2}>{content['Typography2-7']}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles.td}>
                                                    <Grid container alignItems="center" columnSpacing={1.5}>
                                                        <Grid item>
                                                            <Checkbox size="small" sx={styles.checkbox}/>
                                                        </Grid>
                                                        <Grid item>
                                                            <Grid container alignItems="center" spacing={1}>
                                                                <Grid item>
                                                                    <Box component="img" src={content['Image2-4']} sx={styles.img} alt=""/>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Typography variant="subtitle1" sx={styles.title}>{content['Typography2-8']}</Typography>
                                                                    <Typography variant="subtitle2" sx={styles.subtitle2}>{content['Typography2-9']}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles.td}>
                                                    <Grid container alignItems="center" columnSpacing={1.5}>
                                                        <Grid item>
                                                            <Checkbox size="small" sx={styles.checkbox}/>
                                                        </Grid>
                                                        <Grid item>
                                                            <Grid container alignItems="center" spacing={1}>
                                                                <Grid item>
                                                                    <Box component="img" src={content['Image2-5']} sx={styles.img} alt=""/>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Typography variant="subtitle1" sx={styles.title}>{content['Typography2-10']}</Typography>
                                                                    <Typography variant="subtitle2" sx={styles.subtitle2}>{content['Typography2-11']}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles.td}>
                                                    <Grid container alignItems="center" columnSpacing={1.5}>
                                                        <Grid item>
                                                            <Checkbox size="small" sx={styles.checkbox}/>
                                                        </Grid>
                                                        <Grid item>
                                                            <Grid container alignItems="center" spacing={1}>
                                                                <Grid item>
                                                                    <Box component="img" src={content['Image2-6']} sx={styles.img} alt=""/>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Typography variant="subtitle1" sx={styles.title}>{content['Typography2-12']}</Typography>
                                                                    <Typography variant="subtitle2" sx={styles.subtitle2}>{content['Typography2-13']}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={3.66666666667}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Box sx={styles.th}>
                                                    <Typography variant="subtitle2" sx={styles.subtitle}>{content['Typography3-1']}</Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles.td}>
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle1" sx={styles.title}>{content['Typography3-2']}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle2" sx={styles.subtitle2}>{content['Typography3-3']}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles.td}>
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle1" sx={styles.title}>{content['Typography3-4']}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle2" sx={styles.subtitle2}>{content['Typography3-5']}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles.td}>
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle1" sx={styles.title}>{content['Typography3-6']}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle2" sx={styles.subtitle2}>{content['Typography3-7']}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles.td}>
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle1" sx={styles.title}>{content['Typography3-8']}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle2" sx={styles.subtitle2}>{content['Typography3-9']}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles.td}>
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle1" sx={styles.title}>{content['Typography3-10']}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle2" sx={styles.subtitle2}>{content['Typography3-11']}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles.td}>
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle1" sx={styles.title}>{content['Typography3-12']}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle2" sx={styles.subtitle2}>{content['Typography3-13']}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={3.66666666667}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Box sx={styles.th}>
                                                    <Typography variant="subtitle2" sx={styles.subtitle}>{content['Typography4-1']}</Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles.td}>
                                                    <Grid container spacing={0.75}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle2" color="primary">{content['Typography4-2']}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <LinearProgress variant="determinate" color="primary" sx={styles.progress} value={65}/>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles.td}>
                                                    <Grid container spacing={0.75}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle2" color="primary">{content['Typography4-3']}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <LinearProgress variant="determinate" color="primary" sx={styles.progress} value={65}/>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles.td}>
                                                    <Grid container spacing={0.75}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle2" color="primary">{content['Typography4-4']}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <LinearProgress variant="determinate" color="primary" sx={styles.progress} value={65}/>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles.td}>
                                                    <Grid container spacing={0.75}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle2" color="primary">{content['Typography4-5']}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <LinearProgress variant="determinate" color="primary" sx={styles.progress} value={65}/>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles.td}>
                                                    <Grid container spacing={0.75}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle2" color="primary">{content['Typography4-6']}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <LinearProgress variant="determinate" color="primary" sx={styles.progress} value={65}/>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles.td}>
                                                    <Grid container spacing={0.75}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle2" color="primary">{content['Typography4-2']}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <LinearProgress variant="determinate" color="primary" sx={styles.progress} value={65}/>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Box sx={styles.th}></Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles.td} justifyContent="center">
                                                    <Button variant="text" color="info" sx={styles.button}>
                                                        <Box component="img" src={content['Image3-1']} alt=""/>
                                                    </Button>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles.td} justifyContent="center">
                                                    <Button variant="text" color="info" sx={styles.button}>
                                                        <Box component="img" src={content['Image3-2']} alt=""/>
                                                    </Button>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles.td} justifyContent="center">
                                                    <Button variant="text" color="info" sx={styles.button}>
                                                        <Box component="img" src={content['Image3-3']} alt=""/>
                                                    </Button>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles.td} justifyContent="center">
                                                    <Button variant="text" color="info" sx={styles.button}>
                                                        <Box component="img" src={content['Image3-4']} alt=""/>
                                                    </Button>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles.td} justifyContent="center">
                                                    <Button variant="text" color="info" sx={styles.button}>
                                                        <Box component="img" src={content['Image3-5']} alt=""/>
                                                    </Button>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles.td} justifyContent="center">
                                                    <Button variant="text" color="info" sx={styles.button}>
                                                        <Box component="img" src={content['Image3-6']} alt=""/>
                                                    </Button>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container justifyContent="center" alignItems="center" py={1.5} spacing={0.5}>
                                            <Grid item>
                                                <Box component="img" src={content['Image4-1']} sx={styles.img} alt=""/>
                                            </Grid>
                                            <Grid item>
                                                <Link underline="none" sx={styles.link} href="#">{content['Link1-1']}</Link>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <Grid container>
                                    <Grid item xs={3.66666666667}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Box sx={styles.th}>
                                                    <Grid container columnSpacing={1}>
                                                        <Grid item>
                                                            <Checkbox size="small" sx={styles.checkbox}/>
                                                        </Grid>
                                                        <Grid item pt={0.5}>
                                                            <Typography variant="subtitle2" sx={styles.subtitle}>{content['Typography13-1']}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles.td}>
                                                    <Grid container alignItems="center" columnSpacing={1.5}>
                                                        <Grid item>
                                                            <Checkbox size="small" sx={styles.checkbox}/>
                                                        </Grid>
                                                        <Grid item>
                                                            <Grid container alignItems="center" spacing={1}>
                                                                <Grid item>
                                                                    <Box component="img" src={content['Image12-1']} sx={styles.img} alt=""/>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Typography variant="subtitle1" sx={styles.title}>{content['Typography13-2']}</Typography>
                                                                    <Typography variant="subtitle2" sx={styles.subtitle2}>{content['Typography13-3']}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles.td}>
                                                    <Grid container alignItems="center" columnSpacing={1.5}>
                                                        <Grid item>
                                                            <Checkbox size="small" sx={styles.checkbox}/>
                                                        </Grid>
                                                        <Grid item>
                                                            <Grid container alignItems="center" spacing={1}>
                                                                <Grid item>
                                                                    <Box component="img" src={content['Image12-2']} sx={styles.img} alt=""/>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Typography variant="subtitle1" sx={styles.title}>{content['Typography13-4']}</Typography>
                                                                    <Typography variant="subtitle2" sx={styles.subtitle2}>{content['Typography13-5']}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles.td}>
                                                    <Grid container alignItems="center" columnSpacing={1.5}>
                                                        <Grid item>
                                                            <Checkbox size="small" sx={styles.checkbox}/>
                                                        </Grid>
                                                        <Grid item>
                                                            <Grid container alignItems="center" spacing={1}>
                                                                <Grid item>
                                                                    <Box component="img" src={content['Image12-3']} sx={styles.img} alt=""/>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Typography variant="subtitle1" sx={styles.title}>{content['Typography13-6']}</Typography>
                                                                    <Typography variant="subtitle2" sx={styles.subtitle2}>{content['Typography13-7']}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles.td}>
                                                    <Grid container alignItems="center" columnSpacing={1.5}>
                                                        <Grid item>
                                                            <Checkbox size="small" sx={styles.checkbox}/>
                                                        </Grid>
                                                        <Grid item>
                                                            <Grid container alignItems="center" spacing={1}>
                                                                <Grid item>
                                                                    <Box component="img" src={content['Image12-4']} sx={styles.img} alt=""/>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Typography variant="subtitle1" sx={styles.title}>{content['Typography13-8']}</Typography>
                                                                    <Typography variant="subtitle2" sx={styles.subtitle2}>{content['Typography13-9']}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles.td}>
                                                    <Grid container alignItems="center" columnSpacing={1.5}>
                                                        <Grid item>
                                                            <Checkbox size="small" sx={styles.checkbox}/>
                                                        </Grid>
                                                        <Grid item>
                                                            <Grid container alignItems="center" spacing={1}>
                                                                <Grid item>
                                                                    <Box component="img" src={content['Image12-5']} sx={styles.img} alt=""/>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Typography variant="subtitle1" sx={styles.title}>{content['Typography13-10']}</Typography>
                                                                    <Typography variant="subtitle2" sx={styles.subtitle2}>{content['Typography13-11']}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles.td}>
                                                    <Grid container alignItems="center" columnSpacing={1.5}>
                                                        <Grid item>
                                                            <Checkbox size="small" sx={styles.checkbox}/>
                                                        </Grid>
                                                        <Grid item>
                                                            <Grid container alignItems="center" spacing={1}>
                                                                <Grid item>
                                                                    <Box component="img" src={content['Image12-6']} sx={styles.img} alt=""/>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Typography variant="subtitle1" sx={styles.title}>{content['Typography13-12']}</Typography>
                                                                    <Typography variant="subtitle2" sx={styles.subtitle2}>{content['Typography13-13']}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={3.66666666667}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Box sx={styles.th}>
                                                    <Typography variant="subtitle2" sx={styles.subtitle}>{content['Typography14-1']}</Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles.td}>
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle1" sx={styles.title}>{content['Typography14-2']}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle2" sx={styles.subtitle2}>{content['Typography14-3']}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles.td}>
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle1" sx={styles.title}>{content['Typography14-4']}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle2" sx={styles.subtitle2}>{content['Typography14-5']}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles.td}>
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle1" sx={styles.title}>{content['Typography14-6']}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle2" sx={styles.subtitle2}>{content['Typography14-7']}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles.td}>
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle1" sx={styles.title}>{content['Typography14-8']}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle2" sx={styles.subtitle2}>{content['Typography14-9']}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles.td}>
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle1" sx={styles.title}>{content['Typography14-10']}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle2" sx={styles.subtitle2}>{content['Typography14-11']}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles.td}>
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle1" sx={styles.title}>{content['Typography14-12']}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle2" sx={styles.subtitle2}>{content['Typography14-13']}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={3.66666666667}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Box sx={styles.th}>
                                                    <Typography variant="subtitle2" sx={styles.subtitle}>{content['Typography15-1']}</Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles.td}>
                                                    <Grid container spacing={0.75}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle2" color="primary">{content['Typography15-2']}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <LinearProgress variant="determinate" color="primary" sx={styles.progress} value={65}/>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles.td}>
                                                    <Grid container spacing={0.75}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle2" color="primary">{content['Typography15-3']}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <LinearProgress variant="determinate" color="primary" sx={styles.progress} value={65}/>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles.td}>
                                                    <Grid container spacing={0.75}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle2" color="primary">{content['Typography15-4']}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <LinearProgress variant="determinate" color="primary" sx={styles.progress} value={65}/>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles.td}>
                                                    <Grid container spacing={0.75}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle2" color="primary">{content['Typography15-5']}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <LinearProgress variant="determinate" color="primary" sx={styles.progress} value={65}/>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles.td}>
                                                    <Grid container spacing={0.75}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle2" color="primary">{content['Typography15-6']}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <LinearProgress variant="determinate" color="primary" sx={styles.progress} value={65}/>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles.td}>
                                                    <Grid container spacing={0.75}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle2" color="primary">{content['Typography15-7']}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <LinearProgress variant="determinate" color="primary" sx={styles.progress} value={65}/>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Box sx={styles.th}></Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles.td} justifyContent="center">
                                                    <Button variant="text" color="info" sx={styles.button}>
                                                        <Box component="img" src={content['Image13-1']} alt=""/>
                                                    </Button>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles.td} justifyContent="center">
                                                    <Button variant="text" color="info" sx={styles.button}>
                                                        <Box component="img" src={content['Image13-2']} alt=""/>
                                                    </Button>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles.td} justifyContent="center">
                                                    <Button variant="text" color="info" sx={styles.button}>
                                                        <Box component="img" src={content['Image13-3']} alt=""/>
                                                    </Button>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles.td} justifyContent="center">
                                                    <Button variant="text" color="info" sx={styles.button}>
                                                        <Box component="img" src={content['Image13-4']} alt=""/>
                                                    </Button>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles.td} justifyContent="center">
                                                    <Button variant="text" color="info" sx={styles.button}>
                                                        <Box component="img" src={content['Image13-5']} alt=""/>
                                                    </Button>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles.td} justifyContent="center">
                                                    <Button variant="text" color="info" sx={styles.button}>
                                                        <Box component="img" src={content['Image13-6']} alt=""/>
                                                    </Button>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container justifyContent="center" alignItems="center" py={1.5} spacing={0.5}>
                                            <Grid item>
                                                <Box component="img" src={content['Image14-1']} sx={styles.img} alt=""/>
                                            </Grid>
                                            <Grid item>
                                                <Link underline="none" sx={styles.link} href="#">{content['Link2-1']}</Link>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <Grid container>
                                    <Grid item xs={3.66666666667}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Box sx={styles.th}>
                                                    <Grid container columnSpacing={1}>
                                                        <Grid item>
                                                            <Checkbox size="small" sx={styles.checkbox}/>
                                                        </Grid>
                                                        <Grid item pt={0.5}>
                                                            <Typography variant="subtitle2" sx={styles.subtitle}>{content['Typography24-1']}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles.td}>
                                                    <Grid container alignItems="center" columnSpacing={1.5}>
                                                        <Grid item>
                                                            <Checkbox size="small" sx={styles.checkbox}/>
                                                        </Grid>
                                                        <Grid item>
                                                            <Grid container alignItems="center" spacing={1}>
                                                                <Grid item>
                                                                    <Box component="img" src={content['Image22-1']} sx={styles.img} alt=""/>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Typography variant="subtitle1" sx={styles.title}>{content['Typography25-2']}</Typography>
                                                                    <Typography variant="subtitle2" sx={styles.subtitle2}>{content['Typography25-3']}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles.td}>
                                                    <Grid container alignItems="center" columnSpacing={1.5}>
                                                        <Grid item>
                                                            <Checkbox size="small" sx={styles.checkbox}/>
                                                        </Grid>
                                                        <Grid item>
                                                            <Grid container alignItems="center" spacing={1}>
                                                                <Grid item>
                                                                    <Box component="img" src={content['Image22-2']} sx={styles.img} alt=""/>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Typography variant="subtitle1" sx={styles.title}>{content['Typography25-4']}</Typography>
                                                                    <Typography variant="subtitle2" sx={styles.subtitle2}>{content['Typography25-5']}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles.td}>
                                                    <Grid container alignItems="center" columnSpacing={1.5}>
                                                        <Grid item>
                                                            <Checkbox size="small" sx={styles.checkbox}/>
                                                        </Grid>
                                                        <Grid item>
                                                            <Grid container alignItems="center" spacing={1}>
                                                                <Grid item>
                                                                    <Box component="img" src={content['Image22-3']} sx={styles.img} alt=""/>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Typography variant="subtitle1" sx={styles.title}>{content['Typography25-6']}</Typography>
                                                                    <Typography variant="subtitle2" sx={styles.subtitle2}>{content['Typography25-7']}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles.td}>
                                                    <Grid container alignItems="center" columnSpacing={1.5}>
                                                        <Grid item>
                                                            <Checkbox size="small" sx={styles.checkbox}/>
                                                        </Grid>
                                                        <Grid item>
                                                            <Grid container alignItems="center" spacing={1}>
                                                                <Grid item>
                                                                    <Box component="img" src={content['Image22-4']} sx={styles.img} alt=""/>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Typography variant="subtitle1" sx={styles.title}>{content['Typography25-8']}</Typography>
                                                                    <Typography variant="subtitle2" sx={styles.subtitle2}>{content['Typography25-9']}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles.td}>
                                                    <Grid container alignItems="center" columnSpacing={1.5}>
                                                        <Grid item>
                                                            <Checkbox size="small" sx={styles.checkbox}/>
                                                        </Grid>
                                                        <Grid item>
                                                            <Grid container alignItems="center" spacing={1}>
                                                                <Grid item>
                                                                    <Box component="img" src={content['Image22-5']} sx={styles.img} alt=""/>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Typography variant="subtitle1" sx={styles.title}>{content['Typography25-10']}</Typography>
                                                                    <Typography variant="subtitle2" sx={styles.subtitle2}>{content['Typography25-11']}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles.td}>
                                                    <Grid container alignItems="center" columnSpacing={1.5}>
                                                        <Grid item>
                                                            <Checkbox size="small" sx={styles.checkbox}/>
                                                        </Grid>
                                                        <Grid item>
                                                            <Grid container alignItems="center" spacing={1}>
                                                                <Grid item>
                                                                    <Box component="img" src={content['Image22-6']} sx={styles.img} alt=""/>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Typography variant="subtitle1" sx={styles.title}>{content['Typography25-12']}</Typography>
                                                                    <Typography variant="subtitle2" sx={styles.subtitle2}>{content['Typography25-13']}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={3.66666666667}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Box sx={styles.th}>
                                                    <Typography variant="subtitle2" sx={styles.subtitle}>{content['Typography26-1']}</Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles.td}>
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle1" sx={styles.title}>{content['Typography26-2']}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle2" sx={styles.subtitle2}>{content['Typography26-3']}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles.td}>
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle1" sx={styles.title}>{content['Typography26-4']}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle2" sx={styles.subtitle2}>{content['Typography26-5']}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles.td}>
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle1" sx={styles.title}>{content['Typography26-6']}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle2" sx={styles.subtitle2}>{content['Typography26-7']}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles.td}>
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle1" sx={styles.title}>{content['Typography26-8']}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle2" sx={styles.subtitle2}>{content['Typography26-9']}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles.td}>
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle1" sx={styles.title}>{content['Typography26-10']}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle2" sx={styles.subtitle2}>{content['Typography26-11']}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles.td}>
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle1" sx={styles.title}>{content['Typography26-12']}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle2" sx={styles.subtitle2}>{content['Typography26-13']}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={3.66666666667}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Box sx={styles.th}>
                                                    <Typography variant="subtitle2" sx={styles.subtitle}>{content['Typography27-1']}</Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles.td}>
                                                    <Grid container spacing={0.75}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle2" color="primary">{content['Typography27-2']}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <LinearProgress variant="determinate" color="primary" sx={styles.progress} value={65}/>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles.td}>
                                                    <Grid container spacing={0.75}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle2" color="primary">{content['Typography27-3']}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <LinearProgress variant="determinate" color="primary" sx={styles.progress} value={65}/>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles.td}>
                                                    <Grid container spacing={0.75}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle2" color="primary">{content['Typography27-4']}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <LinearProgress variant="determinate" color="primary" sx={styles.progress} value={65}/>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles.td}>
                                                    <Grid container spacing={0.75}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle2" color="primary">{content['Typography27-5']}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <LinearProgress variant="determinate" color="primary" sx={styles.progress} value={65}/>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles.td}>
                                                    <Grid container spacing={0.75}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle2" color="primary">{content['Typography27-6']}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <LinearProgress variant="determinate" color="primary" sx={styles.progress} value={65}/>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles.td}>
                                                    <Grid container spacing={0.75}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle2" color="primary">{content['Typography27-7']}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <LinearProgress variant="determinate" color="primary" sx={styles.progress} value={65}/>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Box sx={styles.th}></Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles.td} justifyContent="center">
                                                    <Button variant="text" color="info" sx={styles.button}>
                                                        <Box component="img" src={content['Image23-1']} alt=""/>
                                                    </Button>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles.td} justifyContent="center">
                                                    <Button variant="text" color="info" sx={styles.button}>
                                                        <Box component="img" src={content['Image23-2']} alt=""/>
                                                    </Button>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles.td} justifyContent="center">
                                                    <Button variant="text" color="info" sx={styles.button}>
                                                        <Box component="img" src={content['Image23-3']} alt=""/>
                                                    </Button>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles.td} justifyContent="center">
                                                    <Button variant="text" color="info" sx={styles.button}>
                                                        <Box component="img" src={content['Image23-4']} alt=""/>
                                                    </Button>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles.td} justifyContent="center">
                                                    <Button variant="text" color="info" sx={styles.button}>
                                                        <Box component="img" src={content['Image23-5']} alt=""/>
                                                    </Button>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={styles.td} justifyContent="center">
                                                    <Button variant="text" color="info" sx={styles.button}>
                                                        <Box component="img" src={content['Image23-6']} alt=""/>
                                                    </Button>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container justifyContent="center" alignItems="center" py={1.5} spacing={0.5}>
                                            <Grid item>
                                                <Box component="img" src={content['Image24-1']} sx={styles.img} alt=""/>
                                            </Grid>
                                            <Grid item>
                                                <Link underline="none" sx={styles.link} href="#">{content['Link3-1']}</Link>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </TabPanel>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default StatTables;