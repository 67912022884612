import React from "react";
import { Grid, Box, Link, Typography, Container, Chip, Button }  from '@mui/material';
import theme from '../../theme/theme';

const Content = (props) => {
    const styles = {
        section: {
            py: theme.spacing(2)
        },
        box: {
            backgroundColor: theme.palette.common.white,
            borderRadius: theme.spacing(0.5),
            boxShadow: theme.shadows[1],
            overflow: 'hidden',
            height: '100%'
        },
        header: {
            pt: theme.spacing(3),
            px: theme.spacing(3),
            pb: theme.spacing(4),
            width: '100%',
        },
        bottom: {
            display: 'flex',
            backgroundColor: theme.palette.grey[50] + '60',
            padding: theme.spacing(3),
            width: '100%'
        },
        subtitle2: {
            color: theme.palette.grey[800],
            mb: theme.spacing(1)
        },
        img: {
            display: 'block'
        },
        pagination: {
            borderRadius: theme.spacing(1),
            width: theme.spacing(1),
            height: theme.spacing(1),
            backgroundColor: theme.palette.grey[100],
            display: 'inline-block'
        },
        subtitle: {
            fontWeight: theme.typography.fontWeightRegular,
            color: theme.palette.grey[500]
        },
        chip: {
            fontSize: theme.spacing(1.5),
            color: theme.palette.error[500]
        },
        active: {
            borderRadius: theme.spacing(1),
            width: theme.spacing(1),
            height: theme.spacing(1),
            backgroundColor: theme.palette.primary[500],
            display: 'inline-block'
        },
        chipWarning: {
            backgroundColor: theme.palette.warning[50],
            fontSize: theme.spacing(1.5),
            color: theme.palette.error[500]
        },
        chipInfo: {
            backgroundColor: theme.palette.info[50],
            fontSize: theme.spacing(1.5),
            color: theme.palette.info[500]
        },
        saly: {
            display: 'block',
            mx: 'auto',
            mb: theme.spacing(3)
        }
    }

    const content = {
        'Typography1-1': 'Announcements',
        'Typography1-2': 'Lorem ipsum dolor sit amet',
        'Typography1-3': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        'Typography1-4': 'Due Date',
        'Chip1-1': '01 June 2023',
        'Button1-1': 'View Details',
        'Image2-1': 'mui-artemis-assets/content/saly.png',
        'Typography2-1': 'Invite Friends',
        'Typography2-2': 'Lorem ipsum dolor sit amet',
        'Button2-1': 'Send Invitation',
        ...props.content
    }

    return (
        <Box component="section" sx={styles.section}>
            <Container>
                <Grid container spacing={3.75} alignItems="stretch">
                    <Grid item xs={12} md={8}>
                        <Box sx={styles.box} display="flex" flexWrap="wrap">
                            <Box sx={styles.header}>
                                <Grid container mb={4} alignItems="center" justifyContent="space-between" spacing={1}>
                                    <Grid item>
                                        <Typography variant="subtitle1" sx={styles.subtitle}>{content['Typography1-1']}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Grid container columnSpacing={2}>
                                            <Grid item>
                                                <Link href="#" sx={styles.pagination}></Link>
                                            </Grid>
                                            <Grid item>
                                                <Link href="#" sx={styles.pagination}></Link>
                                            </Grid>
                                            <Grid item>
                                                <Link href="#" sx={styles.active}></Link>
                                            </Grid>
                                            <Grid item>
                                                <Link href="#" sx={styles.pagination}></Link>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Typography mb={1} variant="h5">{content['Typography1-2']}</Typography>
                                <Typography variant="subtitle1" sx={styles.subtitle}>{content['Typography1-3']}</Typography>
                            </Box>
                            <Box sx={styles.bottom}>
                                <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
                                    <Grid item>
                                        <Typography variant="subtitle2" sx={styles.subtitle2}>{content['Typography1-4']}</Typography>
                                        <Chip size="small" sx={styles.chipWarning} label={content['Chip1-1']}/>
                                    </Grid>
                                    <Grid item>
                                        <Button variant="contained" color="primary">{content['Button1-1']}</Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box sx={styles.box} display="flex" justifyContent="center" alignItems="center" textAlign="center">
                            <Box p={3}>
                                <Box component="img" src={content['Image2-1']} sx={styles.saly} alt=""/>
                                <Typography variant="h5" mb={1}>{content['Typography2-1']}</Typography>
                                <Typography variant="subtitle1" mb={3} sx={styles.subtitle}>{content['Typography2-2']}</Typography>
                                <Button variant="contained" color="primary">{content['Button2-1']}</Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default Content;