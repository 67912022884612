import React from 'react';
import Menu8 from '../components/menus/Menu8';
import Banner5 from '../components/banners/Banner5';
import UserTable1 from '../components/user-tables/UserTable1';

export default function Rank() {
  return (
    
    <React.Fragment>

    <Menu8
        content={{
          'Image1-1':
            '/images/Screenshot-2024-04-19-at-12-22-30-PM-png-1713509736753.webp',
          'Link1-1': 'Home',
          'Link2-1': 'Hackathons',
          'Link5-1': 'Rank',
          'Link6-1': 'Blog',
        }}
      />

      <Banner5
        content={{
          'Typography1-1': 'Top',
          'Typography1-2': 'Rankers',
          'Typography1-3':
            'The Rank is computed from factoes like the Scores from Hackathons, Acadamics &amp; OdinSchool performance etc., You can change the Rank Algorithm to rank based on your requirement',
          'Typography1-4': 'Filter By Skills',
        }}
      />

      <UserTable1
        content={{
          'Typography3-1': 'Rank',
          'Chip3-1': '1',
          'Chip3-2': '2',
          'Chip3-3': '3',
          'Chip3-4': '4',
          'Chip3-5': '5',
          'Chip3-6': '6',
          'Chip3-7': '7',
          'Chip3-8': '8',
          'Chip3-9': '9',
          'Chip3-10': '10',
          'Chip3-11': '11',
          'Chip3-12': '12',
          'Typography4-1': 'Skills',
          'Button4-1': 'SQL',
          'Button4-2': 'React',
          'Button4-3': 'NodeJS',
          'Button4-5': 'React',
          'Button4-7': 'SQL',
          'Button4-9': 'Angular',
          'Button4-11': 'React',
          'Button4-13': 'Java',
          'Button4-15': 'React',
          'Button4-17': 'Java',
          'Button4-19': 'SQL',
          'Button4-21': 'NodeJS',
          'Button4-23': 'Angular',
          'Button4-25': 'NodeJS',
          'Button4-26': 'React',
          'Button4-27': 'Angular',
          'Button4-29': 'MongoDB',
          'Button4-31': 'Java',
          'Button4-33': 'NodeJS',
          'Button4-34': 'React',
          'Button4-37': 'NodeJS',
          'Button4-39': 'React',
          'Button4-41': 'NodeJS',
          'Button4-45': 'React',
        }}
      />
    </React.Fragment>
  );
}

